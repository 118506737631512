import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Componentes
import EstadisticaItem from './EstadisticaItem';

// MUI Componentes
import { 
  Container, 
  Grid, 
  Typography,
  useMediaQuery,
} from '@mui/material';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

// Actions
import { obtener_estadisticas } from '../../redux/action-types/estadisticasActions';

const Presentismo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const matches = useMediaQuery('(min-width:600px)');

  const { estadisticas } = useSelector(state => state.estadisticas);

  const handleClick = id => {
    window.sessionStorage.setItem('urlAnterior', `/dashboard`);
    navigate(`/estadistica/${id}`);
  };

  useEffect(() => {
    dispatch(obtener_estadisticas());
  }, [dispatch]);

  // Agrupo las estadísticas en trios
  const estadisticasAgrupadas = estadisticas.reduce((acc, curr, index) => {
    const group = matches ? 3 : 2;
    const groupIndex = Math.floor(index / group);
  
    if(curr.dependency_id === "UNLP") {
      return acc;
    };
  
    if(!acc[groupIndex]) {
      acc[groupIndex] = [];
    };
  
    acc[groupIndex].push(curr);
  
    return acc;
  }, []);

  if(!estadisticas || estadisticas.length <  1) {
    return (
      <Swiper
        navigation={true}
        modules={[Navigation]}
        style={{
          "--swiper-navigation-color": 'rgb(0, 95, 97)',
        }}
      >
        <SwiperSlide key={12312}>
          <Container
            sx={{
              width: '80%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              container
              sx={{
                width: '100%',
                height: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Typography fontSize={20} fontWeight={"bold"}>
                Hola! No tengo nada para mostrarte ¯\_(ツ)_/¯
              </Typography>
            </Grid>
          </Container>
        </SwiperSlide>
        <SwiperSlide key={12313}>
          <Container
            sx={{
              width: '80%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              item
              container
              sx={{
                width: '100%',
                height: '80%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Typography fontSize={20} fontWeight={"bold"}>
                Pero te deseo un lindo día! 😉
              </Typography>
            </Grid>
          </Container>
        </SwiperSlide>
      </Swiper>
    );
  };
 
  return (
    <Swiper
      navigation={true}
      modules={[Navigation]}
      style={{
        "--swiper-navigation-color": 'rgb(0, 95, 97)',
      }}
    >
      {
        estadisticasAgrupadas.map((grupo, index) => (
          <SwiperSlide key={index}>
            <Container
              sx={{
                width: '80%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Grid
                item
                container
                sx={{
                  width: '100%',
                  height: '80%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                }}
              >
                { 
                  grupo.map(estadistica => (
                    <EstadisticaItem
                      key={estadistica.id}
                      estadistica={estadistica}
                      handleClick={handleClick}
                    />
                  ))
                }
              </Grid>
            </Container>
          </SwiperSlide>
        ))
      }
    </Swiper>
  );
};

export default Presentismo;