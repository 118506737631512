import axios from "axios";

import { get_logs, restaurar } from '../reducers/logsReducer';

import { setAuthorizationInterceptor } from '../../utils/tokenAuthorization';

// Función de utilidad para agregar el token a las solicitudes
setAuthorizationInterceptor();


export const obtener_logs = (date) => {
    return function(dispatch) {
      return axios.get(`${process.env.REACT_APP_BASE_URL}/logs/${date}`)
        .then(response => {
          dispatch(get_logs(response.data));
        })
        .catch(error => console.log(error));
    };
  };

  export const restaurarEstado = () => {
    return function(dispatch) {
      dispatch(restaurar());
    };
  };
