import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Componentes
import InputJustificaciones from './InputJustificaciones';
import ModalComponent from '../ModalComponent/ModalComponent';

// Actions
import { justificacion_personal } from '../../redux/action-types/justificacionesActions';
import { buscar_por_dni } from '../../redux/action-types/usuarioActions';
import { justificacionesPersonalesEnRango } from '../../redux/action-types/auditoriaActions';

// Funciones de ayuda
import { obtenerDia } from '../../utils/obtenerDia';
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import { 
  Box, 
  CircularProgress,
  Grid, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, 
} from '@mui/material';

// MUI iconos
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { Delete } from '@mui/icons-material';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';
import { calcularDiasEntreFechas } from '../../utils/calcularDiasEntreFechas';

// Estado inicial de busqueda
var fechaActual = new Date();
var añoActual = fechaActual.getFullYear();
var fechaInicio = obtenerFecha(new Date(añoActual, 0, 1)).fecha2;
var mesEnMilisegundos = 1000 * 60 * 60 * 24 * 30;
var suma = fechaActual.getTime() + mesEnMilisegundos;
var fechaFin = obtenerFecha(new Date(suma)).fecha2;

const initialState = {
  fechaInicioInput: fechaInicio,
  fechaFinInput: fechaFin,
};

const ListadoJustificacionesPersonales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dni } = useParams();

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const { usuario } = useSelector(state => state.usuario)

  const { justificaciones_personales, mensaje } = useSelector(state => state.justificaciones);
  const { personales_audit } = useSelector(state => state.auditoria)
  const dniActual  = useSelector(state => state.acceso.dni);

  const [ fecha, setFecha ] = useState(initialState);
  const [datosObtenidos, setDatosObtenidos] = useState(false);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleChange = event => {
    const { name, value } = event.target;
    setFecha({
      ...fecha,
      [name]: value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if(fecha.fechaInicioInput !== initialState.fechaInicioInput && fecha.fechaFinInput !== initialState.fechaFinInput) {
      const data = {usuarioActualInput: dniActual, usuarioDni: dni, fechaInicioInput: fecha.fechaInicioInput, fechaFinInput: fecha.fechaFinInput};
      dispatch(justificacion_personal(data));
      if(verificarPermiso(permisos, 'usuario_auditor')){
        dispatch(justificacionesPersonalesEnRango(data));
      }
      
    } else {
      openModal();
    };
  };

  const handleClickCreador = creador => {
    navigate(`/perfil/${creador}`);
  };

  const handleClickJustificacion = (dniActual, id) => {
    window.sessionStorage.setItem('urlAnterior', `/justificaciones-personales/${dni}`);
    navigate(`/detalle-justificacion/${dni}/${id}`);
  };

  const handleClickEliminada = (dniActual, id) => {
    window.sessionStorage.setItem('urlAnterior', `/justificaciones-personales/${dni}`);
    navigate(`/detalle-justificacion-eliminada/${dni}/${id}`);
  };

  useEffect(() => {
    dispatch(buscar_por_dni(dni));
    const data = {usuarioActualInput: dniActual, usuarioDni: dni, fechaInicioInput: initialState.fechaInicioInput, fechaFinInput: initialState.fechaFinInput};
    dispatch(justificacion_personal(data));
    if(verificarPermiso(permisos, 'usuario_auditor')){
      dispatch(justificacionesPersonalesEnRango(data));
    } 
    const temporizador = setTimeout(() => {
      setDatosObtenidos(true);
    }, 1000); 
    
    return () => {
      clearTimeout(temporizador);
    };
  }, [dispatch, dniActual, dni, permisos, mensaje]);

  if(!datosObtenidos && justificaciones_personales.length < 1) {
    return (
      <Grid container spacing={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        {/* Contenedor Grid de los Items */}
        <Grid item container xs={12} sm={10} md={8} lg={6} 
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >   
          {/* Item 1 */}
          <Grid item xs={10} sm={10} md={9.6} lg={11.6} mb={"2em"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
              <FormatListNumberedIcon />
              <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
                {`Justificaciones de ${usuario?.nombre} ${usuario?.apellido}`}
              </Typography>
            </Box>  
          </Grid>
          <Grid item xs={10} sm={10} md={9.6} lg={11.6}>
            <CircularProgress />  
          </Grid> 
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "3vh",
      }}
    >
      {/* Contenedor Grid de los Items */}
      <Grid item container xs={12} sm={10} md={8} lg={6.5} 
        sx={{
          display: "flex",
          justifyContent: "center", 
        }}
      >  
        {/* Item 1 */}
        <Grid item xs={10} sm={10} md={12} lg={12} mb={"2em"}>
        <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
          <FormatListNumberedIcon />
          <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
            {`Justificaciones de ${usuario?.nombre} ${usuario?.apellido}`}
          </Typography>
          </Box>  
        </Grid>
        {/* Componente que toma la fecha para el rango de marcaciones */}
        <InputJustificaciones fechaInicioInput={fecha.fechaInicioInput} fechaFinInput={fecha.fechaFinInput} handleChange={handleChange} handleSubmit={handleSubmit} />
        {/* Item 2 */}
        {justificaciones_personales.length > 0 ?        
        
        <Grid item xs={10} sm={10} md={9.6} lg={12}>
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
            <Table aria-label="simple table"> 
              <TableHead key={"head"}>
                <TableRow key={"0"} sx={{bgcolor:"primary.light"}}>
                  
                  <TableCell key={"day_init"} align="center">Día inicio</TableCell>
                  <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
                  <TableCell key={"day_end"} align="center">Día fin</TableCell>
                  <TableCell key={"date_end"} align="center">Fecha fin</TableCell>      
                  <TableCell key={"cant"} align="center">Total días</TableCell>            
                  <TableCell key={"justify"} align="center">Justificación</TableCell>
                  {verificarPermiso(permisos, 'usuario_auditor') ?
                  <TableCell key={"createdby"} align="center">Creado por</TableCell>
                  :
                  null}
                </TableRow>
              </TableHead>
              {
                <TableBody>
                  {
                    justificaciones_personales && justificaciones_personales.map(justificacion => {
                      const diaI = obtenerDia(justificacion.fecha_inicio);
                      const diaE = justificacion.fecha_inicio === justificacion.fecha_fin ? '-' : obtenerDia(justificacion.fecha_fin);
                      return (
                        <TableRow
                          key={`${justificacion.fecha_inicio}${justificacion.titulo}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          
                          <TableCell key={"dia_inicio"} align="left">{diaI}</TableCell>
                          <TableCell key={"fecha_inicio"} align="left">{obtenerFecha(justificacion.fecha_inicio).fecha}</TableCell>
                          <TableCell key={"dia_fin"} align="left">{diaE}</TableCell>
                          <TableCell key={"fecha_fin"} align="left">{justificacion.fecha_inicio === justificacion.fecha_fin ? '-' : obtenerFecha(justificacion.fecha_fin).fecha}</TableCell>
                          <TableCell key={"cantidad"} align="right">{justificacion.fecha_fin ? calcularDiasEntreFechas(justificacion.fecha_inicio, justificacion.fecha_fin): 1}</TableCell>
                          <TableCell key={"justificacion"} align="left"
                            sx={{
                              '&:hover': {
                                cursor: 'pointer',
                                borderRadius: '.5em',
                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                              }
                            }}
                            onClick={() => handleClickJustificacion(justificacion.dni_creador, justificacion.justificacion_id)}
                          >
                            {justificacion.titulo}
                          </TableCell>
                          {verificarPermiso(permisos, 'usuario_auditor') ?
                          <TableCell key={"creadopor"} align="left" 
                            sx={{
                              '&:hover': {
                                cursor: 'pointer',
                                borderRadius: '.5em',
                                boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                              }
                            }}
                            onClick={() => handleClickCreador(justificacion.dni_creador)}
                          >
                            {justificacion.creador}
                          </TableCell>
                          :
                          null}
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Grid>
        :
        <Grid item xs={10} sm={10} md={9.6} lg={11.6}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
              <Typography component={"h3"} variant={"h6"} fontSize={"1em"}>
                No se encontraron justificaciones...
              </Typography>
            </Box>  
        </Grid>}

        {verificarPermiso(permisos, 'usuario_auditor') && personales_audit.length > 0 ?
        <Grid item container xs={12} sm={12} md={12} lg={12} mt={"3em"}
        sx={{
          display: "flex",
          justifyContent: "center", 
        }}>
          <Grid item xs={10} sm={10} md={9.6} lg={11.6} mb={"2em"}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
            <Delete />
            <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
              {`Justificaciones eliminadas`}
            </Typography>
            </Box>  
          </Grid>
          <Grid item xs={10} sm={10} md={9.6} lg={11.6}>
            <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
              <Table aria-label="simple table"> 
                <TableHead key={"head"}>
                  <TableRow key={"0"} sx={{bgcolor:"auditory.main"}}>
                    <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
                    <TableCell key={"date_end"} align="center">Fecha fin</TableCell>                  
                    <TableCell key={"justify"} align="center">Justificación</TableCell>
                    <TableCell key={"createdat"} align="center">Fecha creacion</TableCell>
                    <TableCell key={"deletedby"} align="center">Eliminado por</TableCell>
                    <TableCell key={"deletedat"} align="center">Fecha eliminacion</TableCell>
                  </TableRow>
                </TableHead>
                {
                  <TableBody>
                    {
                      personales_audit && personales_audit.map(justificacion => {
                        
                        return (
                          <TableRow
                            key={`${justificacion.fecha_inicio}${justificacion.titulo}`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell key={"fecha_inicio"} align="left">{obtenerFecha(justificacion.fecha_inicio).fecha}</TableCell>
                            <TableCell key={"fecha_fin"} align="left">{justificacion.fecha_inicio === justificacion.fecha_fin ? '-' : obtenerFecha(justificacion.fecha_fin).fecha}</TableCell>
                            <TableCell key={"justificacion"} align="left"
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickEliminada(justificacion.dni_creador, justificacion.justificacion_id)}
                            >
                              {justificacion.titulo}
                            </TableCell>
                            <TableCell key={"fecha_creado"} align="left">{obtenerFecha(justificacion.fecha_creacion).fecha}</TableCell>
                            <TableCell key={"eliminadopor"} align="left" 
                              sx={{
                                '&:hover': {
                                  cursor: 'pointer',
                                  borderRadius: '.5em',
                                  boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                                }
                              }}
                              onClick={() => handleClickCreador(justificacion.dni_creador)}
                            >
                              {justificacion.creador}
                            </TableCell>
                            <TableCell key={"fecha_eliminado"} align="left">{obtenerFecha(justificacion.fecha_eliminada).fecha}</TableCell>
                            
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                }
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        :
        null
        }
      </Grid>

      
      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} handleClose={closeModal} >
        <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
          Ingrese una fecha de inicio y una de fin para el reporte.
        </Typography>
      </ModalComponent>
    </Grid>
  );
};

export default ListadoJustificacionesPersonales;
