import React from 'react';
import { useNavigate } from 'react-router';

// MUI componentes
import {
  Button,
  Box,
  Grid,
  Typography,
} from '@mui/material';

// MUI iconos
import Person2Icon from '@mui/icons-material/Person2';

// Utils
import { verificarPermiso } from '../../../utils/verificarPermiso';
import { obtenerFecha } from '../../../utils/obtenerFecha';

const AcercaPerfil = ({ nombre, apellido, dni, genero, ciudad, nacimiento, direccion, mail, telefono, celular, detalle=false, auditoria=null }) => {
  const navigate = useNavigate();

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const handleEditar = () => {
    navigate(`/editar-perfil/${dni}`);
  };

  const handleVersiones = () => {
    window.sessionStorage.setItem('urlAnterior', `/perfil/${dni}`)
    navigate(`/detalle-perfil/${dni}`);
  };

  const parts = nacimiento.split('-');
  const formattedDate = parts[2] + '/' + parts[1] + '/' + parts[0];

  return (
    <Grid item xs={10} sm={10} md={8} lg={6}>
      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}>
        <Person2Icon />
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
          Información Personal
        </Typography>
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", marginBottom:"1em"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          Nombre y Apellido:
        </Typography>
        <Typography>
          {nombre ? nombre : "Sin Detalle"} {apellido ? apellido : "Sin Detalle"}
        </Typography>
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          DNI:
        </Typography>
        <Typography>
          {dni ? dni : "Sin Detalle"}
        </Typography>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Género:
          </Typography>
          <Typography>
            {genero ? genero : "Sin Detalle"}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Nacimiento:
          </Typography>
          <Typography>
            {formattedDate}
          </Typography>
        </Box>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Ciudad:
          </Typography>
          <Typography>
            {ciudad ? ciudad : "Sin Detalle"}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Dirección:
          </Typography>
          <Typography>
            {direccion ? direccion : "Sin Detalle"}
          </Typography>
        </Box>
      </Box>

      {auditoria ?
      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", marginBottom:"1em"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          Pais:
        </Typography>
        <Typography>
          {auditoria.pais}
        </Typography>
      </Box>
      :null}

      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", marginBottom:"1em"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          E-mail:
        </Typography>
        <Typography>
          {mail ? mail : "Sin Detalle"}
        </Typography>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Teléfono:
          </Typography>
          <Typography>
            {telefono ? telefono : "Sin Detalle"}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Celular:
          </Typography>
          <Typography>
            {celular ? celular : "Sin Detalle"}
          </Typography>
        </Box>
      </Box>

      {auditoria ?
      <>
      <Typography component={"h3"} variant={"h6"} mt={"1em"} mb={"1em"} fontSize={"1.2em"} sx={{ textDecoration: 'underline' }}>Datos Auditoría</Typography>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginTop:"1em"}}>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
          Creado por:
        </Typography>
        <Typography>
          {auditoria.creador}
        </Typography>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
          DNI creador:
        </Typography>
        <Typography>
          {auditoria.created_by}
        </Typography>
      </Box>
    </Box>

        <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginTop:"1em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Fecha creacion:
          </Typography>
          <Typography>
            {obtenerFecha(auditoria.created_at).fecha}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Hora creacion:
          </Typography>
          <Typography>
            {obtenerFecha(auditoria.created_at).horario}
          </Typography>
        </Box>
        </Box>
        {
          auditoria.modificador ?
          <>
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginTop:"1em"}}>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
          Ult. vez modificado por:
        </Typography>
        <Typography>
          {auditoria.modificador}
        </Typography>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
          DNI:
        </Typography>
        <Typography>
          {auditoria.updated_by}
        </Typography>
      </Box>
    </Box>

        <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginTop:"1em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Fecha ult. modificación:
          </Typography>
          <Typography>
            {obtenerFecha(auditoria.updated_at).fecha}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Hora modificación:
          </Typography>
          <Typography>
            {obtenerFecha(auditoria.updated_at).horario}
          </Typography>
        </Box>
        </Box>
        </>
          :
          null
        }
      </>
      :
      null
      }

      {
        !detalle && verificarPermiso(permisos, 'usuario_administrador') ?
          <Typography
            onClick={handleEditar}

            sx={{
              maxWidth: "auto",
              display: "inline-block",
              mt: "1em",
              background: 'none',
              fontWeight: "bold",
              border: 'none',
              color: "primary.dark",
              "&:hover": {
                cursor: 'pointer',
              }
            }}
          >
            Modificar datos*
          </Typography>
        :
        null
      }

      {
        
          !detalle && verificarPermiso(permisos, 'super_usuario')
          ?
          <Grid>
            <Button
              type="submit"
              variant="contained"
              onClick={handleVersiones}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Historial de versiones
            </Button>
          </Grid>
          :
            null
        }

    </Grid>
  );
};

export default AcercaPerfil;
