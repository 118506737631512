import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from "../Rotulo/Rotulo";
import Pie from "../Pie/Pie";
import ModalComponent from '../ModalComponent/ModalComponent';

//Actions
import {
  obtener_logs,
  restaurarEstado,
} from "../../redux/action-types/logsActions";

// MUI Components
import { Box, Button, Grid, CircularProgress, Paper, Typography } from "@mui/material";

const Logs = () => {
  const { date } = useParams();

  const { logs } = useSelector((state) => state.logs);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [datosObtenidos, setDatosObtenidos] = React.useState(false);
  // eslint-disable-next-line
  const [timeExceeded, setTimeExceeded] = React.useState(false);
  const [showButton, setShowButton] = useState(false);  // Estado para controlar la visibilidad del botón
  const [isOpenModal, openModal, closeModal] = useModal();

  const logsEndRef = useRef(null);  // Ref para el scroll automático
  const paperRef = useRef(null)

  const convertirFecha = (f) => {
    const partes = f.split("-");
    const dia = parseInt(partes[2]);
    const mes = parseInt(partes[1]) - 1; // Restamos 1 al mes, ya que en JavaScript los meses van de 0 a 11
    const anio = parseInt(partes[0]);
    return `${dia}/${mes + 1}/${anio}`;
  }

  const handleAtras = () => {
    const fechaAtr = new Date(date+"T12:00");
    fechaAtr.setDate(fechaAtr.getDate() - 1)
    var dia = fechaAtr.getDate() < 10 ? `0${fechaAtr.getDate()}` : fechaAtr.getDate();
    var mes = fechaAtr.getMonth() < 9 ? `0${fechaAtr.getMonth() + 1}` : fechaAtr.getMonth() + 1
    navigate(`/logs/${fechaAtr.getFullYear()}-${mes}-${dia}`);
  };

  const handleSig = () => {
    const fechaAtr = new Date(date+"T12:00");
    fechaAtr.setDate(fechaAtr.getDate() + 1)
    var dia = fechaAtr.getDate() < 10 ? `0${fechaAtr.getDate()}` : fechaAtr.getDate();
    var mes = fechaAtr.getMonth() < 9 ? `0${fechaAtr.getMonth() + 1}` : fechaAtr.getMonth() + 1
    console.log(`/logs/${fechaAtr.getFullYear()}-${mes}-${dia}`);
    navigate(`/logs/${fechaAtr.getFullYear()}-${mes}-${dia}`);
  };

  useEffect(() => {
    dispatch(restaurarEstado());
    dispatch(obtener_logs(date));
    setDatosObtenidos(true)
    scrollToBottom();
    const interval = setInterval(() => {
        dispatch(obtener_logs(date));
      }, 300000);
    return () => clearInterval(interval);
  }, [dispatch, date]);

  // Función para hacer scroll automático hacia la última entrada del log
  const scrollToBottom = () => {
    logsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Configura el tiempo límite
    const timeoutId = setTimeout(() => {
      setTimeExceeded(true);
      if(!logs) {
        setTimeout(() => {
          openModal();
          setTimeout(() => {
            navigate("/relojes");
          }, 3000);
        }, 0);
      };
    }, 5000);
    // Limpia el temporizador
    return () => clearTimeout(timeoutId);
  }, [logs, openModal, navigate]);

  // Hacer scroll hacia abajo cada vez que los logs cambien
  useEffect(() => {
    scrollToBottom();
  }, [logs]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = paperRef.current;
    const isAtBottom = scrollHeight - scrollTop === clientHeight;

    // Mostrar el botón solo si no estamos en la parte inferior del log
    setShowButton(!isAtBottom);
  };

  // Si no hay datos del log
  if(!logs) {

    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Logs de " +convertirFecha(date)} />
        {/* Componente de Notificaciones */}
        <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            No hay logs para esa fecha
          </Typography>
        </ ModalComponent>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  if (!datosObtenidos){
    return (
      <>

    {/* Navegación */}
    <Rotulo titulo={"Logs de " + convertirFecha(date)} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
    </>
    );
  }

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={'Logs de ' + convertirFecha(date)} />
      {/* Componente */}
      {/* <Grid
        container
        pt={6}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      > */}
        <Box sx={{ padding: 2 }}>

          {/* Contenedor Paper de Material UI para imitar la consola */}
          <Paper
            elevation={3}
            ref={paperRef}
            onScroll={handleScroll}
            sx={{
              position: "relative",
              padding: 2,
              backgroundColor: "#1e1e1e",
              color: "#ffffff",
              height: "63vh",
              overflowY: "auto",
              fontFamily: "monospace",
              border: '1px solid #333',
            }}
          >
            <pre style={{ margin: 0 }}>{logs["logs"]}</pre>
            <div ref={logsEndRef} />


          </Paper>


          </Box><Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained"
            sx={{
              fontSize: ".8em",
              fontWeight: "bold",
              marginRight: 0.5,
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.primary',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleAtras}
          >
            {"<"}
          </Button>
          <Button variant="contained"
            sx={{
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.primary',
                transform: 'scale(1.01)',
              }
            }}
            disabled = {date === new Date().toISOString().split('T')[0]}
            onClick={handleSig}
          >
            {">"}
          </Button>
          {showButton && (
          <Button
        variant="contained"
        color="primary"
        onClick={scrollToBottom}  // Cuando se hace click, se desplaza al final
        sx={{
          right: 24,   // Espaciado desde el lado derecho
          position: 'fixed',
          zIndex: 1000,
          marginBottom: 2,
          backgroundColor: 'transparent', // Fondo transparente
          '&:hover': {
            backgroundColor: 'background.primary', // Pequeño cambio al pasar el mouse
          },
        }}
      >
        Volver Abajo
      </Button>
          )}
        </Box>
      {/* </Grid> */}
      {/* Componente de Pie de Pag. */}
      <Pie />
    </>
  );
};

export default Logs;
