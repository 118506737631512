import React from 'react';

// MUI Componentes
import {
  Button,
  Grid,
} from '@mui/material';

// Date
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

// MUI Styles
import { inputLabelClasses } from "@mui/material/InputLabel";

const InputDate = ({ inicio, handleChange, handleSubmit, isProcessing }) => {
  const minDate = dayjs('2024-09-01');
  const maxDate = dayjs().subtract(1, 'day'); 

  return (
    <>
      <Grid id={"inputDate"}  container component="form" noValidate onSubmit={handleSubmit} 
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={8} md={5} lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DatePicker 
            id={'inicio'} 
            name="inicio"
            label='Fecha*'
            value={dayjs(inicio)}                    
            onChange={date => handleChange({ target: { value: date } })}
            views={['year', 'month', 'day']}
            minDate={minDate}
            maxDate={maxDate}
            slotProps={{                
              textField: { 
                InputLabelProps: { 
                  sx: {
                    color: 'transparent',
                    [`&.${inputLabelClasses.shrink}`]: {color: "black"}}
              }},
            }}>
          </DatePicker> 
        </Grid>
        <Grid item xs={4} sm={2.5} md={2.5} lg={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >   
          <Button
            type="submit"
            variant="contained"
            disabled={isProcessing}
            sx={{ 
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
          >
            Generar
          </Button> 
        </Grid>
      </Grid>
    </>
  );
};

export default InputDate;