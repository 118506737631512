import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from '../../../hooks/useModal';
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider
} from '@xyflow/react';
 
import '@xyflow/react/dist/style.css';
import './index.css'

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import Dependencia from './Dependencia';
import CustomEdge from './ButtonEdge';
import Pie from "../../Pie/Pie";
import ModalComponent from '../../ModalComponent/ModalComponent';

import {
  Button, 
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material';

// Utils
import { verificarPermiso } from '../../../utils/verificarPermiso';
import { encontrarDiferencias } from '../../../utils/diferenciasEnArreglos';

// Actions
import { getOrganigrama, crearDependencia, restaurarMensajes, modificarConexiones } from '../../../redux/action-types/dependenciaActions';

const ALPHA = /^[\p{L}\s]+$/u;
const validate = (state) => {
  let errors = {};

  if(state.nombreInput === '') {
    errors.nombreInput = '';
  } else if(state.nombreInput.length < 3) {
    errors.nombreInput = 'El nombre debe tener al menos 3 caracteres...';
  } else if(!ALPHA.test(state.nombreInput)) {
    errors.nombreInput = 'Solo se aceptan letras y espacios...';
  };

  if(state.padreInput === '') {
    errors.padreInput = '';
  };
  
  if(state.nombreInput.length > 2 && state.padreInput === '') {
    errors.padreInput = 'Determine la dependencia padre...';
  };

  if(state.emailInput === '') {
    errors.emailInput = '';
  } else if(!state.emailInput.split('@')[0]) {
    errors.emailInput = 'Ingresa el email...';
  } else if(!state.emailInput.endsWith('unlp.edu.ar')) {
    errors.emailInput = 'La dirección debe terminar con el formato "unlp.edu.ar"';
  };

  if(state.descripcionInput.length > 250) {
    errors.descripcionInput = 'La descripcion no puede superar los 250 caracteres'
  }

  return errors;
};

const initialNodes = [];
const initialEdges = [];
const initialState = {
  nombreInput: '',
  descripcionInput: '',
  telefonoInput: '',
  emailInput: ''
};

const nodeTypes = ({ dependencia: Dependencia });

const edgeTypes = ({ button: CustomEdge });

const Organigrama = () => {
  const dispatch = useDispatch();

  const permisos = window.localStorage.getItem("permisos");

  const reactFlowWrapper = useRef(null);
  const edgeReconnectSuccessful = useRef(true);

  const [isOpenModal, openModal, closeModal] = useModal();
  const dniActual  = useSelector(state => state.acceso.dni);

  const { nodos, conectores, text } = useSelector(state => state.dependencia);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [cambios, setCambios] = useState(false);
  const [eliminaciones, setEliminacioes] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isLocked] = useState(true);

  const [pendingConnection, setPendingConnection] = useState(null);

  const [ state, setState ] = useState(initialState);
  const [ errors, setErrors ] = useState(initialState);

  const confirmarCambios = event => {
    event.preventDefault();
    const conexiones = {
      altas: cambios,
      bajas: eliminaciones
    }
    dispatch(modificarConexiones(conexiones));
    openModal();
    setTimeout(() => {      
      closeModal();
      setCambios(false);
      setEliminacioes(false);
      dispatch(restaurarMensajes());
      dispatch(getOrganigrama());     
    }, 3000);
  }

  const handleChange = event => {
    const { name, value } = event.target;
    setErrors(validate({
      ...state,
      [name]: value
    }));
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleClickCancelar = () => {
    setState(initialState);
    closeModal();
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!pendingConnection) return;
    const data = {...state, padreInput: pendingConnection.fromNode.id, usuarioActualInput: dniActual};
    dispatch(crearDependencia(data));
    setTimeout(() => {      
      closeModal();
      setState(initialState);
      dispatch(restaurarMensajes());
      dispatch(getOrganigrama());     
    }, 3000);
    
    setPendingConnection(null); 
  };
  

  const onInit = (rf) => {
    setReactFlowInstance(rf);
  };

  const onConnect = useCallback(
    (params) => {
      const hasParent = edges.some((edge) => edge.target === params.target);
      if (hasParent) {
        alert("Este nodo ya tiene un padre.");
        return;
      }
      const customId = `${params.target}-${params.source}`;

      setEdges((eds) => addEdge({ ...params, id: customId, type: 'button' }, eds));
    },
    [setEdges, edges],
  );

  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const tienePermisoSuperUsuario = verificarPermiso(permisos, 'super_usuario');

  const onConnectEnd = useCallback(
    (event, connectionState) => {
      if (!connectionState.isValid) {
        setPendingConnection(connectionState);
        dispatch(restaurarMensajes());
        openModal();
      }
    },
    [openModal, dispatch],
  );

  useEffect(() => {
    dispatch(getOrganigrama());
  }, [dispatch]);

  useEffect(() => {
    if (nodos && conectores) { 
      setNodes(nodos);
      setEdges(conectores);
    }
  }, [nodos, conectores, setEdges, setNodes]);

  useEffect(() => {
    if (conectores && edges){
      const diferencias = encontrarDiferencias(conectores, edges);
      setEliminacioes(diferencias.enArr1NoEnArr2);
      setCambios(diferencias.enArr2NoEnArr1);
    }
  }, [conectores, edges])

  if (!edges || !nodes){
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={'Organigrama'} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />  
        {/* Copmponente de Pie de Pag. */}
        <Pie />
      </>
    );
  }

  return (
    <>
    <Rotulo titulo={"Organigrama"}></Rotulo>
    <div className="wrapper" ref={reactFlowWrapper} style={{ width: '98vw', height: '73vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={tienePermisoSuperUsuario ? onConnect : undefined}
        onConnectEnd={tienePermisoSuperUsuario ? onConnectEnd : undefined}
        onReconnectStart={tienePermisoSuperUsuario ? onReconnectStart : undefined}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
        onInit={onInit}
        nodesDraggable={!isLocked}
        nodesConnectable={!isLocked} 
        elementsSelectable={!isLocked} 
        edgesConnectable={!isLocked && tienePermisoSuperUsuario}
      >
        <Controls />
        <MiniMap 
          style={{ width: 300, height: 150 }}
          draggable
          zoomable 
          zoomStep={5} 
          pannable 
          nodeColor={"primary.light"}
          maskColor='rgba(225, 225, 220, 0.7)'
          maskStrokeColor='black'
          />
        <Background gap={12} size={1} />
      </ReactFlow>
    </div>

    {cambios?.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          style={{
            position: 'absolute',
            bottom: '250px',
            right: '30px',
          }}
          onClick={confirmarCambios}
        >
          Confirmar cambios
        </Button>
      )}

    {/* Modal */}
    <ModalComponent open={isOpenModal} handleClose={closeModal} > 
    {text ? 
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {text}
        </Typography>
        :        
        <>
        <Grid id={"editar_vacacion"} container component="form" noValidate onSubmit={handleSubmit}>
        <Box sx={{marginBottom: "1em", width:"100%"}}>
        <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
              <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
                Información sobre la nueva dependencia
              </Typography>
            </Box>   
             
            {/* Formulario para Crear */}
            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}}>
              <Typography mr={"1em"} fontWeight={"bold"}>
                Nombre*:
              </Typography>
              <TextField 
                id='nombreInput' 
                name="nombreInput"
                type='text'
                value={state.nombreInput}
                placeholder={"Ingrese el nombre..."} 
                error={!!errors.nombreInput}
                onChange={handleChange}
                inputProps={{
                  autoComplete: 'off'
                }}
                InputLabelProps={{
                  sx: {
                    color: 'rgb(150, 150, 150)',
                  }
                }}
              />
              {
                errors.nombreInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.nombreInput}
                </FormHelperText>
              }
            </Box>

            <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em"}} >
              <Typography mr={"1em"} fontWeight={"bold"}>
                  Descripción:
              </Typography>
              <TextField
              id='motivoInput'
              name="motivoInput"
              type='text'
              value={state.motivoInput}
              placeholder='Max. 250 caracteres'
              error={!!errors.motivoInput}
              fullWidth
              multiline
              rows={3}
              onChange={handleChange}
              InputLabelProps={{
                sx: {
                  color: 'rgb(150, 150, 150)',
                }
              }}
          />
              {
                errors.descripcionInput && 
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {errors.descripcionInput}
                </FormHelperText>
              }
            </Box>

            <Box sx={{with: "100%", display: "flex", justifyContent: "space-between"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Teléfono:
                </Typography>
                <TextField 
                  id='telefonoInput' 
                  name="telefonoInput"
                  type='text'
                  value={state.telefonoInput}
                  placeholder={"Ingrese el teléfono..."} 
                  onChange={handleChange}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  E-mail:
                </Typography>
                <TextField 
                  id='emailInput' 
                  name="emailInput"
                  type='mail'
                  value={state.emailInput}
                  placeholder={"Ingrese el email..."} 
                  error={!!errors.emailInput}
                  onChange={handleChange}
                  inputProps={{
                    autoComplete: 'off'
                  }}
                  InputLabelProps={{
                    sx: {
                      color: 'rgb(150, 150, 150)',
                    }
                  }}
                />
                {
                  errors.emailInput && 
                  <FormHelperText
                    sx={{
                      color: "red",
                    }}
                  >
                    {errors.emailInput}
                  </FormHelperText>
                }
              </Box>
            </Box>
          </Box>
     
          <Box>
            <Button
              variant="contained"
              // onClick={handleClose}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                marginRight: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleClickCancelar}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={errors.nombreInput || errors.descripcionInput || errors.telefonoInput || errors.emailInput || !state.nombreInput }
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'primary.light',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Confirmar
            </Button>
            </Box>
          </Grid>
          </>}
      </ ModalComponent>


    <Pie />
    </>
  );
}

const WrappedOrganigrama = () => (
  <ReactFlowProvider>
    <Organigrama />
  </ReactFlowProvider>
);

export default WrappedOrganigrama;

