import React from 'react';

// Componets
import Presentismo from '../../Estadisticas/Presentismo';

// MUI componentes
import {
  Grid,
} from '@mui/material';

const Slider = () => {
  return (
    <Grid item xs={10} sm={12} md={12} lg={12}
      sx={{
        bgcolor: 'background.paper',
        boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)',
        borderRadius: '.5em',
        height: '25vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Presentismo />
    </Grid>
  );
};

export default Slider;