import React from 'react';
import { useNavigate } from 'react-router';

// Funciones de ayuda
import { obtenerHorario } from '../../../utils/obtenerHorario';
import { obtenerHorasPorDia } from '../../../utils/obtenerHoras';

// MUI componentes
import { 
  Box,
  Button,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// MUI iconos
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

// Utils
import { verificarPermiso } from '../../../utils/verificarPermiso';
import { obtenerFecha } from '../../../utils/obtenerFecha';

const getAlignment = (horasPorDia) => {
  return horasPorDia === '0' ? 'center' : 'left';
};

const diasDeLaSemana = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];

const HorarioPerfil = ({ usuario, auditoria=null }) => {
  const navigate = useNavigate();

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const handleEditar = () => {
    navigate(`/editar-horario/${usuario.dni}`);
  };

  const handleCrear = () => {
    navigate(`/crear-horario/${usuario.dni}`);
  };
  const handleVersiones = () => {
    navigate(`/detalle-horario/${usuario.dni}`);
  };

  const renderTableRow = (dia) => (
    <TableRow
      key={dia.toLowerCase()}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell align="left">{dia}</TableCell>
      <TableCell align={getAlignment(obtenerHorasPorDia(usuario.horario, dia))}>
        {obtenerHorasPorDia(usuario.horario, dia) !== '0' && obtenerHorario(dia, usuario.horario).slice(0, 1) !== ' ' ? obtenerHorario(dia, usuario.horario).slice(0, 5) : obtenerHorasPorDia(usuario.horario, dia) !== '0' ? '00:00' : null}
      </TableCell>
      <TableCell align={getAlignment(obtenerHorasPorDia(usuario.horario, dia))}>
        {obtenerHorasPorDia(usuario.horario, dia) !== '0' && obtenerHorario(dia, usuario.horario).slice(-5) !== '0 -  ' ? obtenerHorario(dia, usuario.horario).slice(-5) : obtenerHorasPorDia(usuario.horario, dia) !== '0' ? '00:00' : null}
      </TableCell>
    </TableRow>
  );

  if(usuario.horario.length < 7) {
    return (
      <>
        <Grid item xs={10} sm={10} md={8} lg={6}>
          <Box sx={{display: "flex", justifyContent: "start", alignItems: "center"}}> 
            <AccessTimeFilledIcon />
            <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
              Horario
            </Typography>
          </Box>  
          <Grid>
            <TableContainer component={"div"}>
              <Table sx={{ width: "80%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Día</TableCell>
                    <TableCell align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Entrada</TableCell>
                    <TableCell align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Salida</TableCell>
                  </TableRow>
                </TableHead>
              </Table >
            </TableContainer>
            <Typography>
              Sin Datos...
            </Typography>
            {
              verificarPermiso(permisos, 'usuario_administrador') ?
                <Typography
                  onClick={handleCrear}
                  disableelevation="true"
                  sx={{
                    mt: "1em",
                    background: 'none',
                    border: 'none',
                    fontWeight: "bold",
                    color: "primary.dark",
                    "&:hover": {
                      cursor: 'pointer',
                    }
                  }}
                >
                  Crear horario*
                </Typography>
              :
              null
            }
          </Grid>
        </Grid>
      </>
    ); 
  };

  return (
    <Grid item xs={10} sm={10} md={8} lg={6}>
      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center"}}> 
        <AccessTimeFilledIcon />
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
          {auditoria? `Horarios vigentes de ${usuario.nombre} ${usuario.apellido}` : "Horario"}
        </Typography>
      </Box>   
      <Grid>
        <TableContainer component={"div"}>
          <Table sx={{ width: "80%" }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Día</TableCell>
                <TableCell align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Entrada</TableCell>
                <TableCell align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Salida</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>                
              {diasDeLaSemana.map(dia => renderTableRow(dia))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {
        auditoria ?
          <>
            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginTop:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Creados por:
                </Typography>
                <Typography>
                  {usuario.creador}
                </Typography>
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  DNI creador:
                </Typography>
                <Typography>
                  {usuario.created_by}
                </Typography>
              </Box>
            </Box>

            <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginTop:"1em"}}>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Fecha creacion:
                </Typography>
                <Typography>
                  {obtenerFecha(usuario.created_at).fecha}
                </Typography>
              </Box>
              <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                <Typography mr={"1em"} fontWeight={"bold"}>
                  Hora creacion:
                </Typography>
                <Typography>
                  {obtenerFecha(usuario.created_at).horario}
                </Typography>
              </Box>
            </Box>
            {
              auditoria.modificador ?
                <>
                  <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginTop:"1em"}}>
                    <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                      <Typography mr={"1em"} fontWeight={"bold"}>
                        Ult. vez modificado por:
                      </Typography>
                      <Typography>
                        {auditoria.modificador}
                      </Typography>
                    </Box>
                    <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                      <Typography mr={"1em"} fontWeight={"bold"}>
                        DNI:
                      </Typography>
                      <Typography>
                        {auditoria.updated_by}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginTop:"1em"}}>
                    <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                      <Typography mr={"1em"} fontWeight={"bold"}>
                        Fecha ult. modificación:
                      </Typography>
                      <Typography>
                        {obtenerFecha(auditoria.ultima_modificacion).fecha}
                      </Typography>
                    </Box>
                    <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
                      <Typography mr={"1em"} fontWeight={"bold"}>
                        Hora modificación:
                      </Typography>
                      <Typography>
                        {obtenerFecha(auditoria.ultima_modificacion).horario}
                      </Typography>
                    </Box>
                  </Box>
                </>
              :
                null
            }
          </> 
        :
          null
      }
      {
        !auditoria && verificarPermiso(permisos, 'usuario_administrador') ?
          <Typography
            onClick={handleEditar}
            sx={{
              maxWidth: "auto",
              display: "inline-block",
              mt: "1em",
              background: 'none',
              border: 'none',
              fontWeight: "bold",
              color: "primary.dark",
              "&:hover": {
                cursor: 'pointer',
              }
            }}
          >
            Modificar horario*
          </Typography>
          :
            null
      }
      {
        !auditoria && verificarPermiso(permisos, 'super_usuario') ?
          <Grid>
            <Button
              type="submit"
              variant="contained"
              onClick={handleVersiones}
              sx={{ 
                fontSize: ".8em",
                fontWeight: "bold",
                marginTop: '1em',
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'background.paper',
                  transform: 'scale(1.01)',
                }
              }}
            >
              Horarios antiguos
            </Button>
          </Grid>
        :
          null
      }
    </Grid>
  );
};  

export default HorarioPerfil;