import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';

//Componentes
import Rotulo from '../../Rotulo/Rotulo';
import Pie from '../../Pie/Pie';
import TablaRoles from './TablaRoles';

// Actions
import { 
  obtener_todos_roles
} from '../../../redux/action-types/rolActions';

// MUI componentes
import {
  Box, 
  Button,
  CircularProgress,
  Divider,
  Grid,
} from '@mui/material';

//Icons
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import PolicyIcon from '@mui/icons-material/Policy';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const RolesSistema = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { consultas, admins, privilegeds, supers, auditores } = useSelector(state => state.rol);

  const [datosObtenidos, setDatosObtenidos] = useState(false);

  const handleAtras = () => {
    navigate('/roles');
  };

  useEffect(() => {
    dispatch(obtener_todos_roles());    
  }, [dispatch]);

  useEffect(() => {
    if(consultas || admins || supers || privilegeds || auditores){
      setDatosObtenidos(true);
    }
  }, [consultas, admins, supers, privilegeds, auditores])

  if(!datosObtenidos){
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Roles en el sistema"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  }

  return (
    <>
    <Rotulo titulo={"Roles en el sistema"} />
    <Grid item container 
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        padding: "1em",
      }}
    >  
     <Grid item container xs={12} sm={10} md={10} lg={8} 
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        > 
        <Grid item xs={12} sm={12} md={12} lg={10}>      
        <Button 
            variant="contained"
            sx={{
            alignSelf: "start",
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
            }
            }}
            onClick={handleAtras}
        >
            Atras
        </Button>           
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"3em", marginTop:"1em"}}>
        <TablaRoles 
        datos={supers}
        nombre="Super"
        icono={<SupervisedUserCircleIcon/>} />
        
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"3em", marginTop:"1em"}}>
        <TablaRoles 
        datos={auditores}
        nombre="Auditores"
        icono={<PolicyIcon/>} />
        
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"3em", marginTop:"1em"}}>
        <TablaRoles 
        datos={privilegeds}
        nombre="Privilegiados"
        icono={<VerifiedUserIcon/>} />
        
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"3em", marginTop:"1em"}} >
        <TablaRoles 
        datos={admins}
        nombre={"Admin"}
        icono={<AdminPanelSettingsIcon/>} />
        
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em", marginTop:"1em"}}>
        <TablaRoles 
        datos={consultas}
        nombre={"Consulta"}
        icono={<FindInPageIcon/>} />
        
      </Box>
      
      </Grid> 
      </Grid> 
    </Grid>
    {/* Pie de Pág */}
    <Pie />
    </>
  );
};  

export default RolesSistema;