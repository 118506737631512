import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useModal } from '../../../hooks/useModal';

// Componentes
import ModalComponent from '../../ModalComponent/ModalComponent';

// Actions
import { descargar_marcaciones_xlsx, descargar_marcaciones_PDF } from '../../../redux/action-types/marcacionesActions';

// MUI Componentes
import {
  Button,
  CircularProgress,
  Grid,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

// Date
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

// MUI Icons
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';

// MUI Styles
import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled, alpha } from '@mui/material/styles';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 140,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const InputDate = ({ inicio, fin, handleChange, handleSubmit, dependencia_id, dependencia_name, dni_usuario='', isProcessing, setIsProcessing, con_hijos=false}) => {
  const dispatch = useDispatch();

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ isOpenModal, openModal, closeModal ] = useModal();
  
  const open = Boolean(anchorEl);

  const handleDownloadXlsx = (event) => {    
    setIsProcessing(true);
    openModal();
    setAnchorEl(null);
    const data = {inicio: inicio, fin:fin, dependencia_id: dependencia_id, dependencia_name: dependencia_name, dni_usuario: dni_usuario, con_hijos:con_hijos};
    dispatch(descargar_marcaciones_xlsx(data))
    .then(() => {
      setIsProcessing(false);
      closeModal();
    });
  };

  const handleDownloadPDF = (event) => {    
    setIsProcessing(true);
    openModal();
    setAnchorEl(null);
    const data = {inicio: inicio, fin:fin, dependencia_id: dependencia_id, dependencia_name: dependencia_name, dni_usuario: dni_usuario, con_hijos:con_hijos};
    dispatch(descargar_marcaciones_PDF(data))
    .then(() => {
      setIsProcessing(false);
      closeModal();
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid id={"inputDate"}  container component="form" noValidate onSubmit={handleSubmit} 
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={6} sm={6} md={4} p={1}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <DatePicker 
            id={'inicio'} 
            name="inicio"
            label='Fecha Inicio*'
            value={dayjs(inicio)}                    
            onChange={date => handleChange({ target: { value: date, name: 'inicio' } })}
            views={['year', 'month', 'day']}
            slotProps={{                
              textField: { 
                InputLabelProps: { 
                  sx: {
                    color: 'transparent',
                    [`&.${inputLabelClasses.shrink}`]: {color: "black"}}
              }},
            }}>
          </DatePicker> 
          
        </Grid>
        <Grid item xs={6} sm={6} md={4} p={1}
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <DatePicker 
            id={'fin'} 
            name="fin"
            label='Fecha Fin*'
            value={dayjs(fin)}
            views={['year', 'month', 'day']}
            onChange={date => handleChange({ target: { value: date, name: 'fin' } })}
            slotProps={{
              textField: { 
                InputLabelProps: { 
                  sx: {
                    color: 'transparent',
                    [`&.${inputLabelClasses.shrink}`]: {color: "black"}}
                }
              },
            }}>          
          </DatePicker>  
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >   
          <Button
            type="submit"
            variant="contained"
            disabled={isProcessing}
            sx={{ 
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
          >
            Generar
          </Button> 
        </Grid>
        <Grid item xs={6} sm={6} md={2} lg={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Button        
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="contained"
          disabled={isProcessing}
            sx={{ 
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
        >
          <FileDownloadIcon aria-label="Download" color="primary" />
        </Button>
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >        
          <MenuItem onClick={handleDownloadXlsx}>
            <ListItemText>Excel</ListItemText>
            <DescriptionIcon color="success"></DescriptionIcon>
          </MenuItem>
          <MenuItem onClick={handleDownloadPDF}>
            <ListItemText>PDF</ListItemText>
            <PictureAsPdfIcon color="error" aria-label="PDF" ></PictureAsPdfIcon>
          </MenuItem>        
        </StyledMenu>
        </Grid>
      </Grid>

      {/* Componente de Notificaciones */}
      <ModalComponent open={isOpenModal} >
        <Grid container 
          sx={{
            display: "flex",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
              Generando...
          </Typography>
          <CircularProgress sx={{ marginLeft: "3em" }} size={30}/>
        </Grid>
      </ModalComponent>
    </>
  );
};

export default InputDate;