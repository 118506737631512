import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

//Actions
import {
  listar_usuarios_dependencia,
  restaurar_mensajes,
} from "../../redux/action-types/designacionActions";

import PropTypes from "prop-types";

//Ayuda
import { obtenerFecha } from "../../utils/obtenerFecha";

// MUI componentes
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  TablePagination,
  Toolbar,
  Tooltip,
  IconButton,
} from "@mui/material";

import { alpha } from "@mui/material/styles";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";

function descendingComparator(a, b, orderBy) {
  // console.log(b);
  if (orderBy === "oficina") {
    if (b.designacion.dependency.name < a.designacion.dependency.name) {
      return -1;
    }
    if (b.designacion.dependency.name > a.designacion.dependency.name) {
      return 1;
    }
  }
  if (orderBy === "fecha") {
    if (b.designacion.fecha_inicio < a.designacion.fecha_inicio) {
      return -1;
    }
    if (b.designacion.fecha_inicio > a.designacion.fecha_inicio) {
      return 1;
    }
  }if (orderBy === "fecha_out") {
    if (b.designacion.fecha_fin < a.designacion.fecha_fin) {
      return -1;
    }
    if (b.designacion.fecha_fin > a.designacion.fecha_fin) {
      return 1;
    }
    if (b.designacion.fecha_fin) {
      return -1
    }
    if (a.designacion.fecha_fin) {
      return 1
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  // console.log(order, orderBy)
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  // console.log(array, comparator)
  const stabilizedThis = array?.map((el, index) => [el, index]);
  // console.log("antesdelsort", stabilizedThis)
  stabilizedThis.sort((a, b) => {
    // console.log(a, b)
    const order = comparator(a[0], b[0]);
    //console.log(order)
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  // console.log("despuesdelsort", stabilizedThis)
  return stabilizedThis.map((el) => el[0]);
}

const headCellsVigentes = [
  {
    id: "dni",
    numeric: false,
    disablePadding: false,
    label: "DNI",
  },
  {
    id: "apellido",
    numeric: false,
    disablePadding: false,
    label: "Apellido",
  },
  {
    id: "nombre",
    numeric: false,
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "cargo",
    numeric: false,
    disablePadding: true,
    label: "Cargo",
  },
  {
    id: "fecha",
    numeric: false,
    disablePadding: true,
    label: "Fecha de Designación",
  },
  {
    id: "oficina",
    numeric: false,
    disablePadding: true,
    label: "Oficina",
  },
];

const headCellsHistoricos = [
  {
    id: "dni",
    numeric: false,
    disablePadding: false,
    label: "DNI",
  },
  {
    id: "apellido",
    numeric: false,
    disablePadding: false,
    label: "Apellido",
  },
  {
    id: "nombre",
    numeric: false,
    disablePadding: true,
    label: "Nombre",
  },
  {
    id: "cargo",
    numeric: false,
    disablePadding: true,
    label: "Cargo",
  },
  {
    id: "fecha",
    numeric: false,
    disablePadding: true,
    label: "Fecha de Designación",
  },
  {
    id: "fecha_out",
    numeric: false,
    disablePadding: true,
    label: "Fecha de Cese",
  },
];

function EnhancedTableHeadV(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    console.log(property);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ bgcolor: "primary.light" }}>
        {headCellsVigentes.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id !== "cargo" ? <>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                color:
                  orderBy === headCell.id
                    ? "palette.background.paper"
                    : "palette.text.secondary,",
                "&:hover": {
                  color: "palette.background.paper",
                  transform: "scale(1.02)",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span">
                  {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                </Box>
              ) : null}
            </TableSortLabel>
            </> :
            <>
              {headCell.label}
            </>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadV.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHeadH(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ bgcolor: "primary.light" }}>
        {headCellsHistoricos.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id !== "cargo" ? <>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span">
                  {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                </Box>
              ) : null}
            </TableSortLabel>
            </> :
            <>
            {headCell.label}
            </>}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadH.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ListadoUsuarios() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { vigentes, historicos } = useSelector((state) => state.designaciones);

  const [datosObtenidos, setDatosObtenidos] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("apellido");
  const [selected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tab, setTab] = useState("1");

  let vigentesFiltered = useMemo(() => {
    return [...new Set(
      vigentes?.filter(
        (obj, index, self) => index === self.findIndex((t) => t.dni === obj.dni)
      )
    ),
  ]}, [vigentes]);
  let historicosFiltered = useMemo(() => { return [
    ...new Set(
      historicos?.filter(
        (obj, index, self) => index === self.findIndex((t) => t.dni === obj.dni)
      )
    ),
  ]}, [historicos]);

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRowsVigentes = React.useMemo(
    () =>
      stableSort(
        datosObtenidos ? vigentesFiltered : [],
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, datosObtenidos, vigentesFiltered]
  );

  const visibleRowsHistorico = React.useMemo(
    () =>
      stableSort(
        datosObtenidos ? historicosFiltered : [],
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, datosObtenidos, historicosFiltered]
  );

  const handleClickCreador = (creador) => {
    window.sessionStorage.setItem('urlAnterior', `/usuarios-dependencia/${id-8}`);
    navigate(`/perfil/${creador}`);
  };

  useEffect(() => {
    dispatch(listar_usuarios_dependencia(id));
    setTimeout(() => {
      dispatch(restaurar_mensajes());
      setDatosObtenidos(true);
    }, 1000);
  }, [dispatch, id, datosObtenidos]);

  if (!datosObtenidos) {
    return (
      <Grid container>
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      </Grid>
    );
  }

  return (
    <>
      <Grid item container>
        <TabContext value={tab}>
          <Grid
            item
            container
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList onChange={handleTab} aria-label="tabs">
              <Tab
                label="Vigentes"
                value={"1"}
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  color: "text.default",
                }}
              />
              <Tab
                label="Historicos"
                value={"2"}
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  color: "text.default",
                }}
              />
            </TabList>
          </Grid>
        </TabContext>
      </Grid>
      {tab === "1" ? (
        <>
          {vigentesFiltered && vigentesFiltered.length > 0 ? (
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHeadV
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={datosObtenidos ? vigentesFiltered.length : 0}
                    />
                    <TableBody>
                      {visibleRowsVigentes.map((row, index) => {
                        return (
                          <TableRow
                            key={row.dni}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              id={row.dni}
                              scope="row"
                              padding="normal"
                              sx={{
                                "&:hover": {
                                  cursor: "pointer",
                                  borderRadius: ".5em",
                                  boxShadow:
                                    "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                },
                              }}
                              onClick={() => handleClickCreador(row.dni)}
                            >
                              {row.dni}
                            </TableCell>
                            <TableCell align="left">{row.apellido}</TableCell>
                            <TableCell align="left">{row.nombre}</TableCell>
                            <TableCell align="left">
                              {row.designacion?.cargo?.codigo}
                            </TableCell>
                            <TableCell align="left">
                              {
                                obtenerFecha(row.designacion?.fecha_inicio)
                                  .fecha
                              }
                            </TableCell>
                            <TableCell align="left">
                              {row.designacion?.dependency?.name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, vigentesFiltered.length]}
                  component="div"
                  count={datosObtenidos ? vigentesFiltered.length : 0}
                  labelRowsPerPage={"Elementos por Pagina"}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "left",
                ml: "-3px",
                marginRight: "25px",
              }}
            >
              <Typography component={"h3"} variant={"h6"} fontSize={"1em"}>
                No se encontraron usuarios...
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <>
          {historicosFiltered && historicosFiltered.length > 0 ? (
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHeadH
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={datosObtenidos ? historicosFiltered.length : 0}
                    />
                    <TableBody>
                      {visibleRowsHistorico.map((row, index) => {
                        return (
                          <TableRow
                            key={row.dni}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              id={row.dni}
                              scope="row"
                              padding="normal"
                              sx={{
                                "&:hover": {
                                  cursor: "pointer",
                                  borderRadius: ".5em",
                                  boxShadow:
                                    "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                },
                              }}
                              onClick={() => handleClickCreador(row.dni)}
                            >
                              {row.dni}
                            </TableCell>
                            <TableCell align="left">{row.apellido}</TableCell>
                            <TableCell align="left">{row.nombre}</TableCell>
                            <TableCell align="left">
                              {row.designacion?.cargo?.codigo}
                            </TableCell>
                            <TableCell align="left">
                              {
                                obtenerFecha(row.designacion?.fecha_inicio)
                                  .fecha
                              }
                            </TableCell>
                            <TableCell align="left">
                              {obtenerFecha(row.designacion?.fecha_fin).fecha}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, historicosFiltered.length]}
                  component="div"
                  count={datosObtenidos ? historicosFiltered.length : 0}
                  labelRowsPerPage={"Elementos por Pagina"}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "left",
                ml: "-3px",
                marginRight: "25px",
              }}
            >
              <Typography component={"h3"} variant={"h6"} fontSize={"1em"}>
                No se encontraron usuarios...
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}
