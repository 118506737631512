import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
// import { useDecrypt } from '../../hooks/useDecrypt';
import { useModal } from '../../hooks/useModal';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import AcercaPerfil from './Componentes/AcercaPerfil';
import ListadoAuditoria from './ListadoAuditoria';
import Pie from '../Pie/Pie';
import ModalComponent from '../ModalComponent/ModalComponent';

// Actions
import { buscar_por_dni_audit, restaurarMensajes, eliminarUsuario } from '../../redux/action-types/usuarioActions';
import { historialUsuario } from '../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Box,
  Button,
  Grid,
  Divider,
  CircularProgress,
  Typography,
} from '@mui/material';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';

//MUI ICONS
import HistoryIcon from '@mui/icons-material/History';

const initialState = {
  dni: '',
  delete: false,
};

const AuditoriaUsuario = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();

  const { usuario } = useSelector(state => state.usuario);
  const { historial_versiones } = useSelector(state => state.auditoria)

  const [isOpenModal, openModal, closeModal] = useModal();
  const [ state ] = useState(initialState);

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  useEffect(() => {
    dispatch(buscar_por_dni_audit(dni));
    if (verificarPermiso(permisos, 'super_usuario')){
      dispatch(historialUsuario(dni));
    }
  }, [dispatch, dni, permisos]);

  const handleAtras = () => {
    navigate(window.sessionStorage.getItem('urlAnterior'));
  };

  const handleClickDelete = (dni) => {
    state.delete= true;
    state.dni = dni;
    // setState({
    //   ...state,
    //   delete: true,
    //   dni: dni,
    // });
    dispatch(restaurarMensajes());
    openModal();
  };

  const handleDelete = () => {
    const data = {...state, dni: usuario.dni}
    dispatch(eliminarUsuario(data));
    navigate('/');
  };

  if(!usuario) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Detalle Justificación"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={"Detalle e historial del perfil"} />
      <Grid container spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
      >
        <Grid item xs={10} sm={8} md={8} lg={8} >
          <Grid sx={{display:'flex', justifyContent:'space-between'}}>
          <Button
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleAtras}
          >
            Atrás
          </Button>
          {!usuario.eliminado ?
          <Button
              type="submit"
              variant="contained"
              sx={{
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'auditory.main',
                '&:hover': {
                  bgcolor: 'auditory.secondary',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={() => handleClickDelete(dni)}
            >
              Eliminar
            </Button>
            :
            <></>
          }</Grid>
          <Divider sx={{ width: "100%", height: "1em" }}/>
        </Grid>
        <Grid item container xs={12} sm={10} md={8} lg={8} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Datos del perfil */}
          <AcercaPerfil
            nombre={usuario.nombre}
            apellido={usuario.apellido}
            dni={usuario.dni}
            genero={usuario.genero}
            ciudad={usuario.ciudad}
            nacimiento={usuario.nacimiento}
            direccion={usuario.direccion}
            mail={usuario.email}
            telefono={usuario.telefono}
            celular={usuario.celular}
            detalle={true}
            auditoria={{"pais":usuario.pais, "creador": usuario.creador, "created_by": usuario.created_by, "created_at": usuario.created_at,
                "modificador":usuario.modificador, "updated_at": usuario.updated_at, "updated_by": usuario.updated_by
            }}
          />
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={10}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
          <Grid item xs={10} sm={10} md={8} lg={10} mb={"2em"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}>
              <HistoryIcon />
              <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
                {`Historial de versiones`}
              </Typography>
              </Box>
          </Grid>
          {
            verificarPermiso(permisos, 'super_usuario') && historial_versiones && historial_versiones.length > 0 ?
            <ListadoAuditoria />
            :
            <Grid item xs={10} sm={10} md={9.6} lg={11.6} mb={"2em"}>
              <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}>
                <Typography component={"h6"} variant={"h6"} fontSize={"1em"} ml={"2px"}>
                  {`Este usuario nunca fue modificado`}
                </Typography>
              </Box>
            </Grid>
          }
        </Grid>
      </Grid>
      <Pie />

    {/* Componente de Notificaciones */}
    <ModalComponent open={isOpenModal} handleClose={closeModal} >
    {

      /*Si no hay mensaje y el flag de delete esta en true, se muestra el componente para eliminar */
      state.delete ?
      <>
        <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
          Esta por eliminar el usuario porque se creo mal. ¿Desea continuar?
        </Typography>
        <Button
          type="submit"
          variant="contained"
          onClick={closeModal}
          sx={{
            fontSize: ".8em",
            fontWeight: "bold",
            marginTop: '1em',
            marginRight: '1em',
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={handleDelete}
          sx={{
            fontSize: ".8em",
            fontWeight: "bold",
            marginTop: '1em',
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'secondary.light',
              transform: 'scale(1.01)',
            }
          }}
        >
          Eliminar
        </Button>
      </>
    /*FIN DE MODAL DE ELIMINACION */
      :
    /*SI EL FLAG ES FALSE, MUESTRA EL COMPONENTE DE CIERRE */
      <></>

    }
  </ModalComponent>
    </>
  );
};

export default AuditoriaUsuario;