import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';

// Componentes
import ModalComponent from '../../ModalComponent/ModalComponent';

// Actions
import { marcaciones_dni, marcaciones_dni_actual, marcacion_remota, restaurarMensajes } from '../../../redux/action-types/marcacionesActions';

// Funciones de ayuda
import { obtenerDia } from '../../../utils/obtenerDia';
import { obtenerFecha } from '../../../utils/obtenerFecha';

// MUI componentes
import { 
  Box,
  Button,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// MUI iconos
import {
  DoneAll,
} from '@mui/icons-material';

// Utils
import { verificarPermiso } from '../../../utils/verificarPermiso';

const MarcacionesPerfil = ({ dni, inicio, fin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dniActual  = useSelector(state => state.acceso.dni);
  const { status, mensaje } = useSelector(state => state.marcaciones);
  
  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const { marcaciones_rango, sereno } = useSelector(state => state.marcaciones);

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleMarcaciones = () => {
    navigate(`/marcaciones/${dni}`);
  };

  const handleClick = (event) => {
    event.preventDefault();
    openModal();
  };

  useEffect(() => {
    const data = {dni, dniActual, inicio: inicio, fin: fin};
    dispatch(dni === dniActual ? marcaciones_dni_actual(data) : marcaciones_dni(data));
  }, [dispatch, dni, dniActual, fin, inicio])

  const handleClickMarcacion = (event) => {
    event.preventDefault();
    dispatch(marcacion_remota({marcacion_remota: true, dni_usuario: dni}));
    openModal();
    setTimeout(() => {
      dispatch(restaurarMensajes());
      closeModal();
      navigate(`/marcaciones/${dni}`);
    }, 2000);
  };

  return (
    <Grid item xs={10} sm={10} md={8} lg={6}>
      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center"}}> 
        <DoneAll />
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
          Últimas Marcaciones
        </Typography>
      </Box>   
      <Grid>
          <TableContainer component={"div"}>
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableHead >
                <TableRow  key={'1'}>
                  <TableCell key={"Día"} align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Día</TableCell>
                  <TableCell key={"Fecha"} align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Fecha</TableCell>
                  <TableCell key={"Entrada"} align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Entrada</TableCell>
                  <TableCell key={"Salida"} align="left" sx={{ fontWeight:"bold", color: "text.primary" }}>Salida</TableCell>
                </TableRow>
              </TableHead>
              {
                <TableBody key={"body"}>
                  {
                    marcaciones_rango && marcaciones_rango.map(marcacion => {
                      const dia = obtenerDia(marcacion.fecha_marcacion);
                      const fecha = obtenerFecha(marcacion.fecha_marcacion).fecha;
                      return (
                        <TableRow
                          key={`${dia}${fecha}`}                          
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell key={dia} align="left">{dia}</TableCell>
                          <TableCell key={fecha} align="left">{fecha}</TableCell>
                          <TableCell key={`${fecha}1`}>{sereno ? (marcacion.entrada === '15:00' ? null : marcacion.entrada) : (marcacion.entrada === '00:00' ? null : marcacion.entrada)}</TableCell>
                          <TableCell key={`${fecha}2`}>{sereno ? (marcacion.salida === '15:00' ? null : marcacion.salida) : (marcacion.salida === '00:00' ? null : marcacion.salida)}</TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              }
            </Table>
          </TableContainer>
        </Grid>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography
            onClick={handleMarcaciones}
            sx={{
              color: "primary.dark",
              fontWeight: "bold",
              "&:hover": {
                cursor: 'pointer',
              }
            }}
          >
            Ver más*
          </Typography>

          {
            dniActual === dni && verificarPermiso(permisos, 'super_usuario')
            ?
              <Button
                type="submit"
                variant="contained"
                onClick={handleClick}
                sx={{ 
                  fontSize: ".8em",
                  fontWeight: "bold",
                  marginTop: '1em',
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
              >
                Marcación Remota
              </Button>
            :
              null
          }
        </div>

        {/* Componente de Notificaciones */}
        <ModalComponent open={isOpenModal} handleClose={closeModal} >
          {
            status
            ?
            <>
              <Typography id="modal-modal-title" component="h2"sx={{ fontSize: '1.3em' }} >
                {status}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {mensaje}
              </Typography>
            </>
            :
            <>
              <Typography id="modal-modal-title" component="h2" sx={{ fontSize: '1.3em' }}>
                Esta por crear una marcación remota. ¿Desea continuar?
              </Typography>
              <Button
                type="submit"
                variant="contained"
                onClick={closeModal}
                sx={{ 
                  fontSize: ".8em",
                  fontWeight: "bold",
                  marginTop: '1em',
                  marginRight: '1em',
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleClickMarcacion}
                sx={{ 
                  fontSize: ".8em",
                  fontWeight: "bold",
                  marginTop: '1em',
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
              >
                Crear
              </Button>
            </>
          }
        </ModalComponent>
    </Grid>
  );
};  

export default MarcacionesPerfil;