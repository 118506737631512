import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';

// Utils
import { obtenerFecha } from '../../utils/obtenerFecha';
import { traducirDetalle } from '../../utils/traducirDetalle';

// Acciones
import { buscar_por_dni, buscar_por_dni_actual } from '../../redux/action-types/usuarioActions';
import { historial_completo_dni } from '../../redux/action-types/vacacionesActions';


// MUI Componentes
import {
  Button, 
  Box,
  CircularProgress,
  Divider,  
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';

const VacacionesHistoricasUsuario = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { dni } = useParams();

    const dniActual = window.localStorage.getItem("dni");
    const permisos = window.localStorage.getItem("permisos");

    const { usuario_vacacion, historial_completo, } = useSelector(state => state.vacaciones);
    const { usuario } = useSelector(state => state.usuario);

    const esInvierno = obtenerFecha(new Date()).esInvierno


    const handleClickJustificacion = () => {
      window.sessionStorage.setItem('urlAnterior', `/vacaciones/${dni}`);
      navigate(`/vacaciones-historicas/${dni}`);
    };

    const handleAtras = () => {
        navigate(window.sessionStorage.getItem('urlAnterior'));
      };

    useEffect(() => {
        dispatch(dni === dniActual ? buscar_por_dni_actual(dniActual) : buscar_por_dni(dni ? dni : dniActual));
    }, [dispatch, dni, dniActual, permisos]);

    useEffect(()=> {
      dispatch(historial_completo_dni(dni));
    }, [dispatch, dni, permisos]);

  if (!usuario){
    return(
      <>
      <Rotulo titulo={"Vacaciones Historicas"} />
        <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
        {/* Pie de Pág. */}
        <Pie />
      </>
    )
  };

  if (usuario && !usuario_vacacion){
    return(
      <>
      <Rotulo titulo={"Vacaciones Historicas"} />
      <Grid container spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
        >
        <Grid item container xs={12} sm={10} md={8} lg={8} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
      <Grid item xs={10} sm={10} md={8} lg={7}>
      <Grid item container xs={11} sm={11} md={12} lg={12}>
        <Button 
          variant="contained"
          sx={{
            mr: 2, 
            mb: 2,
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          //onClick={handleAtras}
        >
          Atrás
        </Button>
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
        {esInvierno? <DownhillSkiingIcon/> :<BeachAccessIcon />}
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"} >          
          {`Vacaciones Historicas de ${usuario.nombre} ${usuario.apellido}`}
        </Typography>
      </Box>
      
      <CircularProgress
          sx={{
            width: "auto",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />

      
      <Typography component={"h3"} variant={"h6"} mt={"1em"} mb={"1em"}
      fontWeight={"bold"}>{`Detalle de vacaciones en el período`}</Typography>
      
      </Grid>
      </Grid>
      </Grid>
      {/* Modal */}

      <Pie />
    </>

    )
  };

  return (
    <>
      <Rotulo titulo={"Vacaciones Historicas"} />
      {/* Contenedor del componente */}
      <Grid container spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
        >
        <Grid item container xs={12} sm={10} md={8} lg={8} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
      <Grid item xs={10} sm={10} md={10} lg={10}>
      <Grid item container xs={11} sm={11} md={12} lg={12}>
        <Button 
          variant="contained"
          sx={{
            mr: 2, 
            mb: 2,
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          onClick={handleAtras}
        >
          Atrás
        </Button>
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "2em"}}> 
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.6em"} >          
          {`Vacaciones Historicas de ${usuario.nombre} ${usuario.apellido}`}
        </Typography>
      </Box>    
      
      <Box mb={"2em"}>
        {historial_completo && Object.keys(historial_completo).reverse().map(año => (
            <>
        <Box mb={"2em"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "0.5em"}}> 
            <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.3em"} >          
              {año}
            </Typography>
          </Box>
          <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
          <Table aria-label="simple table"> 
             <TableHead key={"head"}>
               <TableRow key={"0"} sx={{bgcolor:"primary.main"}}>
                 <TableCell key={"date_init"} align="center">Fecha inicio</TableCell>
                 <TableCell key={"date_end"} align="center">Fecha fin</TableCell>                  
                 <TableCell key={"justify"} align="center">Cantidad días</TableCell>
                 <TableCell key={"deletedby"} align="center">Detalle</TableCell>
               </TableRow>
             </TableHead>
               <TableBody>
                 {historial_completo[año].map((entrada) => {
                     return (
                       <TableRow
                         key={`${entrada.id}$`}
                         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                       >
                         <TableCell align="center"
                         >{obtenerFecha(entrada.fecha_inicio).fecha}</TableCell>
                         <TableCell align="center"                            
                         >{entrada.fecha_inicio === entrada.fecha_fin ? "-": obtenerFecha(entrada.fecha_fin).fecha}</TableCell>
                         <TableCell align="center"                             
                         >
                           {entrada.dias}
                         </TableCell>
                         {entrada.justificacion ?
                         <TableCell align="center" 
                         sx={{
                           '&:hover': {
                             cursor: 'pointer',
                             borderRadius: '.5em',
                             boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)'
                           }
                         }}
                         onClick={() => handleClickJustificacion(entrada.justificacion.id)}
                       >
                         {traducirDetalle(entrada.operacion)}
                       </TableCell>
                         :
                         <TableCell align="center">{traducirDetalle(entrada.operacion)}</TableCell>}
                       </TableRow>
                     );
                   })
                 }
               </TableBody>
           </Table>
         </TableContainer>
         </Box>
         </>
        ))}           
      </Box>
      

      </Grid>
      </Grid>
      </Grid>
      {/* Pie de Pág. */}
      <Pie />
    </>
  );
};

export default VacacionesHistoricasUsuario;