import React from 'react';
import { Handle, Position } from '@xyflow/react';

// MUI componentes
import { 
  Box,
  Button,
  Divider,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { useNavigate } from 'react-router';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'primary.light',
    color: 'white',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const Dependencia = ({ data, isConnectable }) => {
  const navigate = useNavigate();

  const { label, cabecera, id } = data;

  const titulo = label.length > 70 ? label.split(' ').splice(0, 5).join(' ') + '(...)' : label;

  const handleDetalles = (id) => {
    window.sessionStorage.setItem("urlAnterior", `/organigrama`);
    navigate(`/dependencia/${id}`)      
  };

  const handleReporte = (id) => {
    window.sessionStorage.setItem("urlAnterior", `/organigrama`);
    navigate(`/reportes-generales/${id}/solo`)      
  };

  const handleReporteCompleto = (id) => {
    window.sessionStorage.setItem("urlAnterior", `/organigrama`);
    navigate(`/reportes-generales/${id}/completo`)      
  };

  return (
    <HtmlTooltip 
      placement="right" 
      arrow
      enterDelay={1500}
      title={
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
          <Button 
            sx={{
              fontSize: "1em",
              color: "white",
              fontWeight: "bold",
              justifyContent: "center",
              alignContent: "center",
            }}
            onClick={() => handleDetalles(id)}>
              Detalles
          </Button>
          <Divider 
            sx={{
              width: "100%",   
              alignSelf: "stretch", 
              bgcolor: "white", 
            }}>
          </Divider>
          <Button 
              sx={{
                fontSize: "1em",
                color: "white",
                fontWeight: "bold",
                justifyContent: "center",
                alignContent: "center",
              }}
              onClick={() => handleReporte(id)}>
                Reporte
            </Button>
            <Divider 
              sx={{
                width: "100%",   
                alignSelf: "stretch", 
                bgcolor: "white", 
              }}>
            </Divider>
            <Button 
              sx={{
                fontSize: "1em",
                color: "white",
                fontWeight: "bold",
                justifyContent: "center",
                alignContent: "center"
              }}
              onClick={() => handleReporteCompleto(id)}>
                Reporte con suboficinas
            </Button>
          </Box>
        </React.Fragment>}
    >
      <div style={{ pointerEvents: 'auto' }}>
        <Box maxWidth="full" component="div"
          sx={{
            width: cabecera ? '30em' : "15em",
            height: cabecera ? '7em' : "5em",
            minHeight: '5em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '.5em',
            borderRadius: '.5em',
            padding: ".5em",
            boxShadow: cabecera ? "1px 1px 3px 3px rgba(40,40,40,0.2)" :'1px 1px 3px 3px rgba(40,40,40,0.4)',
            bgcolor: cabecera ? 'primary.light' : 'background.paper',
          }}
        > 
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
          />          
          {
            cabecera ?
            <ApartmentIcon sx={{ fontSize:"4.5em", color: 'background.paper'  }} />
            :
            <HomeWorkIcon sx={{ fontSize:"2.5em", color: 'primary.light'  }} />
          }             
            <Box component="div" 
              sx={{
                width: "100%",
                display: "flex",
                flexDirection:"column",
                marginLeft: ".5em"
              }}
            >
              <Box component="div" >
                <Typography variant="p" sx={{fontWeight: cabecera ? 'bold' : "", fontSize: cabecera ? "1.2em" : "0.9em", color: cabecera ? 'background.paper' : 'black'}} >
                  {titulo}
                </Typography>
              </Box>
            </Box>
          <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
          />
        </Box>
      </div>
    </HtmlTooltip>
  );
};

export default Dependencia;