import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  estadisticas: [],
};

export const estadisticasSlice = createSlice({
  name: 'estadisticasReducer',
  initialState,
  reducers: {
    buscar_todas: (state, { payload }) => {
      return {
        ...state,
        estadisticas: payload,
      };
    },
    buscar_id: (state, { payload }) => {
      return {
        ...state,
        estadistica: payload,
      };
    },
  },
});

export const { buscar_todas, buscar_id } = estadisticasSlice.actions;

export default estadisticasSlice.reducer;