import { configureStore } from '@reduxjs/toolkit';

import sideBarReducer from '../reducers/sideBarReducer';
import relojReducer from '../reducers/relojReducer';
import accesoReducer from '../reducers/accesoReducer';
import usuarioReducer from '../reducers/usuarioReducer';
import horarioReducer from '../reducers/horarioReducer';
import marcacionesReducer from '../reducers/marcacionesReducer';
import dependenciaReducer from '../reducers/dependenciaReducer';
import justificacionesReducer from '../reducers/justificacionesReducer';
import rolReducer from '../reducers/rolReducer';
import designacionReducer from '../reducers/designacionReducer';
import cargosReducer from '../reducers/cargosReducer';
import messageReducer from '../reducers/messageReducer';
import clockReducer from '../reducers/clockReducer';
import auditoriaReducer from '../reducers/auditoriaReducer';
import vacacionesReducer from '../reducers/vacacionesReducer';
import estadisticasReducer from '../reducers/estadisticasReducer';
import logsReducer from '../reducers/logsReducer';

const store = configureStore({
  reducer: {
    sidebar: sideBarReducer,
    auditoria: auditoriaReducer,
    acceso: accesoReducer,
    usuario: usuarioReducer,
    horario: horarioReducer,
    clock: clockReducer,
    dependencia: dependenciaReducer,
    reloj: relojReducer,
    rol: rolReducer,
    marcaciones: marcacionesReducer,
    justificaciones: justificacionesReducer,
    designaciones: designacionReducer,
    vacaciones: vacacionesReducer,
    cargos: cargosReducer,
    estadisticas: estadisticasReducer,
    logs: logsReducer,

    mensaje: messageReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
