import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

// Componentes
import Rotulo from '../Rotulo/Rotulo';
import InputDate from './InputDate';
import Pie from '../Pie/Pie';

// Actions
import { obtener_estadisticas } from '../../redux/action-types/estadisticasActions';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

import PropTypes from "prop-types";

// MUI Componentes
import { 
  Box, 
  Button, 
  CircularProgress,
  Divider, 
  Grid, 
  Paper,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';

function descendingComparator(a, b, orderBy) {
  if (orderBy === "dependencia") {
    if (b.nombre_dependencia < a.nombre_dependencia) {
      return -1;
    };

    if (b.nombre_dependencia > a.nombre_dependencia) {
      return 1;
    };
  };

  if (orderBy === "vigentes") {
    if (b.usuarios_vigentes < a.usuarios_vigentes) {
      return -1;
    };

    if (b.usuarios_vigentes > a.usuarios_vigentes) {
      return 1;
    };
  };
  
  if (orderBy === "marcaciones") {
    if (b.cantidad_marcaciones < a.cantidad_marcaciones) {
      return -1;
    };

    if (b.cantidad_marcaciones > a.cantidad_marcaciones) {
      return 1;
    };
  };

  if (orderBy === "tasa") {
    if (b.tasa_asisitencia < a.tasa_asisitencia) {
      return -1;
    };

    if (b.tasa_asisitencia > a.tasa_asisitencia) {
      return 1;
    };
  };

  if (orderBy === "presentes") {
    if (b.empleados_presentes < a.empleados_presentes) {
      return -1;
    };

    if (b.empleados_presentes > a.empleados_presentes) {
      return 1;
    };
  };

  if (orderBy === "justificados") {
    if (b.empleados_justificados < a.empleados_justificados) {
      return -1;
    };

    if (b.empleados_justificados > a.empleados_justificados) {
      return 1;
    };
  };

  if (orderBy === "ausentes") {
    if (b.empleados_ausentes < a.empleados_ausentes) {
      return -1;
    };

    if (b.empleados_ausentes > a.empleados_ausentes) {
      return 1;
    };
  };
  
  if (b[orderBy] < a[orderBy]) {
    return -1;
  };

  if (b[orderBy] > a[orderBy]) {
    return 1;
  };

  return 0;
};

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: "dependencia",
    numeric: false,
    disablePadding: false,
    label: "Dependencia",
  },
  {
    id: "vigentes",
    numeric: false,
    disablePadding: false,
    label: "Vigentes",
  },
  {
    id: "marcaciones",
    numeric: false,
    disablePadding: true,
    label: "Marcaciones",
  },
  {
    id: "tasa",
    numeric: false,
    disablePadding: true,
    label: "Tasa(%)",
  },
  {
    id: "presentes",
    numeric: false,
    disablePadding: true,
    label: "Presentes",
  },
  {
    id: "justificados",
    numeric: false,
    disablePadding: true,
    label: "Justificados",
  },
  {
    id: "ausentes",
    numeric: false,
    disablePadding: true,
    label: "Ausentes",
  },
];

function EnhancedTableHeadV(props) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ bgcolor: "primary.light" }}>
        {headCells.map((cell) => (
          <TableCell
            key={cell.id}
            align="center"
            padding="normal"
            sortDirection={orderBy === cell.id ? order : false}
          >
            {
              <>
                <TableSortLabel
                  active={orderBy === cell.id}
                  direction={orderBy === cell.id ? order : "asc"}
                  onClick={createSortHandler(cell.id)}
                  sx={{
                    color:
                      orderBy === cell.id
                        ? "palette.background.paper"
                        : "palette.text.secondary,",
                    "&:hover": {
                      color: "palette.background.paper",
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  {cell.label}
                </TableSortLabel>
              </> 
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHeadV.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// Estado inicial de busqueda
var fechaActual = new Date();
var diaEnMilisegundos = 1000 * 60 * 60 * 24;
var resta = fechaActual.getTime() - diaEnMilisegundos;
var fechaInicio = obtenerFecha(new Date(resta)).fecha2;

const TablaEstadistica = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { estadisticas } = useSelector(state => state.estadisticas);

  const [ fecha, setFecha ] = useState(fechaInicio);
 
  const [ isProcessing, setIsProcessing ] = useState(false);

  const [ datosObtenidos, setDatosObtenidos ] = useState(false);
  const [ order, setOrder ] = useState("asc");
  const [ orderBy, setOrderBy ] = useState("dependencia");
  const [ selected ] = useState([]);

  const handleAtras = () => {
    navigate(-1);
  };
  
  const handleChange = (event) => {
    const { value } = event.target;
    setFecha(value);
  };

  const handleClick = (id) => {
    window.sessionStorage.setItem('urlAnterior', `/estadisticas/tabla`);
    navigate(`/estadistica/${id}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsProcessing(true);
    dispatch(obtener_estadisticas(fecha))
      .then(() => {
        setIsProcessing(false);
      });
  };

  let estadisticasFiltered = useMemo(() => {
    return [...new Set(
      estadisticas?.filter(
        (obj, index, self) => index === self.findIndex((t) => t.nombre_dependencia === obj.nombre_dependencia)
      )
    ),
  ]}, [estadisticas]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };  
  
  const estadisticasVisibles = React.useMemo(
    () =>
      stableSort(
        datosObtenidos ? estadisticasFiltered : [],
        getComparator(order, orderBy)
      ),
    [ order, orderBy, datosObtenidos, estadisticasFiltered]
  );
  
  useEffect(() => {
    dispatch(obtener_estadisticas());
    setTimeout(() => {
      setDatosObtenidos(true);
    }, 1000);
  }, [dispatch]);

  if(!estadisticas) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Estadísticas"} />
        <Grid container spacing={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "0.5vh",
          }}
        >
          {/* Botonera */}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Button variant="contained"
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleAtras}
              >
                Atrás
              </Button>
            </Box>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          {/* Progress */}
          <Grid>
            <CircularProgress 
              sx={{
                width: "auto",
                height: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />  
          </Grid>
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  if(estadisticas.length < 1) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Estadísticas"} />
        <Grid container spacing={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0.5vh",
          }}
        >
          {/* Botonera */}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Button variant="contained"
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleAtras}
              >
                Atrás
              </Button>
            </Grid>
            <Grid item>
              <InputDate 
                inicio={fecha} 
                handleChange={handleChange} 
                handleSubmit={handleSubmit} 
                isProcessing={isProcessing}
                />
            </Grid>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          {/* Progress */}
          <Grid item xs={11} sm={10} md={10} lg={10}> 
            <Typography>
              No hay datos para esta fecha...
            </Typography>
          </Grid>
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (  
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={`Estadísticas`} />
      <Grid container spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.5vh",
        }}
      >
        <Grid item container xs={12} sm={12} md={12} lg={12}
          sx={{
            display: "flex",
            height: "auto",
            justifyContent: "center",
          }}
        >
          {/* Botonera */}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Button variant="contained"
                sx={{
                  fontSize: ".8em",
                  fontWeight: "bold",
                  bgcolor: 'background.paper',
                  '&:hover': {
                    bgcolor: 'background.paper',
                    transform: 'scale(1.01)',
                  }
                }}
                onClick={handleAtras}
              >
                Atrás
              </Button>
            </Grid>
            <Grid item>
              <InputDate 
                inicio={fecha} 
                handleChange={handleChange} 
                handleSubmit={handleSubmit} 
                isProcessing={isProcessing}
                />
            </Grid>
            <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
          </Grid>
          
          {/*Marcaciones*/}
          <Grid item container xs={11} sm={10} md={10} lg={6.1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          > 
            {  
              estadisticasFiltered && estadisticasFiltered.length > 0 ?
                <TableContainer component={Paper} style={{ boxShadow: '1px 1px 3px 3px rgba(40,40,40,0.4)' }}>
                <Table sx={{ minWidth: 300 }} aria-label="tabla-estadisticas">
                  {/* Cabecera */}
                  <EnhancedTableHeadV
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={datosObtenidos ? estadisticasFiltered.length : 0}
                  />
                  {/* Datos */}
                  <TableBody>
                    {
                      estadisticasVisibles && estadisticasVisibles.map(estadistica => {
                        return (
                          <TableRow
                            key={`${estadistica.nombre_dependencia}`}
                          >
                            <TableCell align="center"
                              sx={{
                                "&:hover": {
                                  cursor: estadistica.dependency_id === "UNLP" ? "default" : "pointer",
                                  borderRadius: ".5em",
                                  boxShadow: estadistica.dependency_id === "UNLP" ? "none" : "1px 1px 3px 3px rgba(40,40,40,0.4)",
                                },
                              }}
                              onClick={estadistica.dependency_id === "UNLP" ? null : () => handleClick(estadistica.id)}
                            > 
                              {estadistica.nombre_dependencia}
                            </TableCell>
                            <TableCell align="center"> {estadistica.usuarios_vigentes }</TableCell>
                            <TableCell align="center"> {estadistica.cantidad_marcaciones}</TableCell>
                            <TableCell align="center"> 
                              {estadistica.empleados_presentes > 0 || estadistica.empleados_justificados > 0 || estadistica.empleados_ausentes > 0
                                ? `${estadistica?.tasa_asistencia}` : 'S/C'}
                            </TableCell>
                            <TableCell align="center"> {estadistica.empleados_presentes}</TableCell>
                            <TableCell align="center"> {estadistica.empleados_justificados}</TableCell>
                            <TableCell align="center"> {estadistica.empleados_ausentes}</TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              :
                <Grid>
                  <CircularProgress 
                    sx={{
                      width: "auto",
                      height: "auto",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                    }}
                  />  
                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      {/*Pie de Pag.*/}
      <Pie />
    </>
  );
};

export default TablaEstadistica;