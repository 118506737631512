import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';

//Componentes
import Rotulo from '../Rotulo/Rotulo';
import Pie from '../Pie/Pie';

// Actions
import { usuarios_sin_vacaciones } from '../../redux/action-types/vacacionesActions';

// MUI componentes
import {
  Box, 
  Button,
  CircularProgress,
  Divider,
  Grid,
} from '@mui/material';

//Icons
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TablaUsuarios from './TablaUsuarios';


const UsuariosSinVacaciones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dniActual  = useSelector(state => state.acceso.dni);

  const { usuarios } = useSelector(state => state.vacaciones);

  const [datosObtenidos, setDatosObtenidos] = useState(false);

  const handleAtras = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    dispatch(usuarios_sin_vacaciones());    
  }, [dispatch]);

  useEffect(() => {
    if(usuarios){
      setDatosObtenidos(true);
    }
  }, [usuarios])

  if(!datosObtenidos){
    return (
      <>
        {/* Navegación */}
        <Rotulo titulo={"Roles en el sistema"} />
        <Grid container>
          <CircularProgress
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  }

  return (
    <>
    <Rotulo titulo={"Roles en el sistema"} />
    <Grid item container 
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        padding: "1em",
      }}
    >  
     <Grid item container xs={12} sm={10} md={10} lg={8} 
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        > 
        <Grid item xs={12} sm={12} md={12} lg={10}>      
        <Button 
            variant="contained"
            sx={{
            alignSelf: "start",
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
            }
            }}
            onClick={handleAtras}
        >
            Atras
        </Button>           
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"3em", marginTop:"1em"}}>
        <TablaUsuarios 
        datos={usuarios}
        nombre="Usuarios sin vacaciones"
        icono={<SupervisedUserCircleIcon/>}
        dniActual={dniActual} />
        
      </Box>

      {/* <Box sx={{display: "flex", flexDirection: "column", marginBottom:"3em", marginTop:"1em"}} >
        <TablaRoles 
        datos={admins}
        nombre={"Admin"}
        icono={<AdminPanelSettingsIcon/>} />
        
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", marginBottom:"1em", marginTop:"1em"}}>
        <TablaRoles 
        datos={consultas}
        nombre={"Consulta"}
        icono={<FindInPageIcon/>} />
        
      </Box> */}
      
      </Grid> 
      </Grid> 
    </Grid>
    {/* Pie de Pág */}
    <Pie />
    </>
  );
};  

export default UsuariosSinVacaciones;