import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';

// Componentes
import Rotulo from '../../Rotulo/Rotulo';
import HorariosPerfil from '../Componentes/HorariosPerfil';
import ListadoAuditoria from './ListadoAuditoria';
import Pie from '../../Pie/Pie';

// Actions
import { buscar_por_dni } from '../../../redux/action-types/usuarioActions';
import { historialHorarios } from '../../../redux/action-types/auditoriaActions';

// MUI componentes
import {
  Box,
  Button, 
  Grid,
  Divider,
  CircularProgress,
  Typography,
} from '@mui/material';

// Utils
import { verificarPermiso } from '../../../utils/verificarPermiso';

//MUI Icons
import HistoryIcon from '@mui/icons-material/History';

const AuditoriaHorario = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dni } = useParams();
  
  const { usuario } = useSelector(state => state.usuario);
  const { historial_versiones } = useSelector(state => state.auditoria)

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  useEffect(() => {
    dispatch(buscar_por_dni(dni));
    if (verificarPermiso(permisos, 'super_usuario')){
      dispatch(historialHorarios(dni));
    }
  }, [dispatch, dni, permisos]);

  const handleAtras = () => {
    navigate(`/perfil/${dni}`);
  };

  if(!usuario) {
    return (
      <>
        {/* Rótulo de Página */}
        <Rotulo titulo={"Detalle Justificación"} />
        <Grid container>
          <CircularProgress 
            sx={{
              width: "auto",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />  
        </Grid>
        {/* Pie de Pág */}
        <Pie />
      </>
    );
  };

  return (
    <>
      {/* Rótulo de Página */}
      <Rotulo titulo={"Detalle e historial de los horarios"} />
      <Grid container spacing={6} 
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1vh",
        }}
        >
        <Grid item xs={10} sm={8} md={7} lg={8}>
          <Button 
            variant="contained"
            sx={{
              alignSelf: "start",
              fontSize: ".8em",
              fontWeight: "bold",
              bgcolor: 'background.paper',
              '&:hover': {
                bgcolor: 'background.paper',
                transform: 'scale(1.01)',
              }
            }}
            onClick={handleAtras}
          >
            Atrás
          </Button>
          <Divider sx={{ width: "100%", height: "1em" }}/>
        </Grid>
        <Grid item container xs={12} sm={10} md={8} lg={8} ml={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* Datos del perfil */}
          <HorariosPerfil
            usuario={usuario}
            auditoria={{"modificador": historial_versiones?.modificador, "updated_by": historial_versiones?.updated_by,
            "ultima_modificacion": historial_versiones?.updated_at}}
          />
          </Grid>
          <Grid item container xs={10} sm={10} md={10} lg={6}
        sx={{
          display: "flex",
          justifyContent: "center", 
        }}>
          <Grid item xs={10} sm={10} md={9.6} lg={11.6} mb={"2em"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
              <HistoryIcon />
              <Typography component={"h3"} variant={"h6"} fontSize={"1.4em"} ml={"2px"}>
                {`Historial de versiones`}
              </Typography>
              </Box>  
          </Grid>
          {verificarPermiso(permisos, 'super_usuario') && historial_versiones && historial_versiones.horarios?.length > 0 ?
        
          <ListadoAuditoria />       
          :
          <Grid item xs={10} sm={10} md={9.6} lg={11.6} mb={"2em"}>
            <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", ml: "-3px"}}> 
              <Typography component={"h6"} variant={"h6"} fontSize={"1em"} ml={"2px"}>
                {`Los horarios nunca fueron modificados`}
              </Typography>
            </Box>  
          </Grid>
          }  
          
        </Grid>
      </Grid>
      
      
      <Pie /> 
    </>
  );
};

export default AuditoriaHorario;