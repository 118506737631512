import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    logs: [],
    mensaje: '',
}

export const logsSlice = createSlice({
    name: 'logsReducer',
    initialState,
    reducers: {
        get_logs: (state, { payload }) => {
            return {
                ...state,
                logs: payload,
            }
        },
        restaurar: (state) => {
            return {
              ...state,
              logs: null,
            };
          },
    }
});

export const { get_logs, restaurar } = logsSlice.actions;

export default logsSlice.reducer;
