import { Box, IconButton, Pagination, PaginationItem } from '@mui/material';

import { FirstPage, LastPage } from '@mui/icons-material';

const PaginationWithDynamicDots = ({ count, page, rowsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(count / rowsPerPage);

  const handleClick = (event, newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(event, newPage - 1);
    };
  };

  return (
    <Box sx={{display: "flex", justifyContent: "space-evenly", alignContent: "center", alignItems: "center"}}>
      <IconButton
        sx = {{ color: "primary.light" }}
        onClick={(event) => handleClick(event, 1)}
        disabled={page === 0}
        aria-label="Primera página"
      >
        <FirstPage />
      </IconButton>
      <Pagination
        sx={{ width: "25em" }}
        count={totalPages}
        page={page + 1}
        siblingCount={2}
        boundaryCount={0}
        renderItem={(item) => (
          <PaginationItem
            sx = {{ color: "primary.main" }}
            {...item}
            page={item.page}
            onClick={(event) => handleClick(event, item.page)}
          />
        )}        
      />  
      <IconButton
        sx = {{ color: "primary.light" }}
        onClick={(event) => handleClick(event, totalPages)}
        disabled={page === totalPages - 1}
        aria-label="Última página"
      >
        <LastPage />
      </IconButton>
    </Box>
  );
};

export default PaginationWithDynamicDots;