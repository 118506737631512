import React from 'react';
import { useNavigate } from 'react-router';
// import { useDecrypt } from '../../hooks/useDecrypt';

// Funciones de ayuda
import { obtenerFecha } from '../../utils/obtenerFecha';

// MUI componentes
import { 
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

// MUI iconos
import ApartmentIcon from '@mui/icons-material/Apartment';

// Utils
import { verificarPermiso } from '../../utils/verificarPermiso';
import { calcularDiasEntreFechas } from '../../utils/calcularDiasEntreFechas';


const AcercaJustificacion = ({ id, dni, nombre, inicio, fin, justificacion, dependencia, cabecera, general, creador, fecha_creacion,handleClick, eliminada=false, fecha_eliminacion=null, eliminador=null }) => {
  const navigate = useNavigate();

  const permisos = window.localStorage.getItem("permisos");
  // const permisos = useDecrypt('permisos');

  const handleAtras = () => {
    const URL = window.sessionStorage.getItem('urlAnterior');
    navigate(URL);
  };

  const handleEditar = () => {
    navigate(`/editar-justificacion-personal/${dni}/${id}`);
  };

  const handleEditarGeneral = () => {
    navigate(`/editar-justificacion-general/${id}`);
  };

  return (
    <Grid item xs={10} sm={10} md={8} lg={8}>
      <Grid item container xs={11} sm={11} md={12} lg={12}>
        <Button 
          variant="contained"
          sx={{
            mr: 2, 
            mb: 2,
            fontSize: ".8em",
            fontWeight: "bold",
            bgcolor: 'background.paper',
            '&:hover': {
              bgcolor: 'background.paper',
              transform: 'scale(1.01)',
            }
          }}
          onClick={handleAtras}
        >
          Atrás
        </Button>
        <Divider sx={{ width: "100%", height: "1em", mb: "1em" }}/>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "start", alignItems: "center", mb: "1em"}}> 
        <ApartmentIcon />
        <Typography component={"h3"} variant={"h6"} ml={"3px"} fontSize={"1.4em"}>
          {eliminada ?
          "Auditoria de la justificación eliminada"
          :
          "Información sobre la Justificación" }
        </Typography>
      </Box>    

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
          Justificación:
        </Typography>
        <Typography>
          {justificacion}
        </Typography>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          Nota:
        </Typography>
        <Typography>
          {general ? "Justificación General" : "Justificación Personal"}
        </Typography>
      </Box>
      </Box>

      {general ? 
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
          Dependencia:
          </Typography>
          <Typography>
          {cabecera?.name}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
          {cabecera?.id !== dependencia?.id ? "Oficina:" : null}
          </Typography>
          <Typography>
          {cabecera?.id !== dependencia?.id ? dependencia.name : null}
          </Typography>
        </Box>
      </Box>
      :
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Usuario:
          </Typography>
          <Typography>
            {nombre}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            DNI usuario:
          </Typography>
          <Typography>
            {dni}
          </Typography>
        </Box>
      </Box>      
      }

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Fecha inicio:
          </Typography>
          <Typography>
            {inicio ? obtenerFecha(inicio).fecha : "Sin Detalle"}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Fecha fin:
          </Typography>
          <Typography>
            {fin !== inicio ? obtenerFecha(fin).fecha : "Sin Detalle"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            Días justificados:
          </Typography>
          <Typography>
            
            {fin ? calcularDiasEntreFechas(inicio, fin) + " días": "1 día"}
          </Typography>
        </Box>
        
      </Box>

      {verificarPermiso(permisos, 'usuario_auditor') ?
      <Typography component={"h3"} variant={"h6"} mt={"1em"} mb={"1em"} fontSize={"1.2em"} sx={{ textDecoration: 'underline' }}>Datos Auditoría</Typography>
      :null}

      {verificarPermiso(permisos, 'usuario_auditor') ?
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Typography mr={"1em"} fontWeight={"bold"}>
            Creador:
          </Typography>
          <Typography>
            {creador?.nombre}
          </Typography>
        </Box>
        <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
          <Typography mr={"1em"} fontWeight={"bold"}>
            DNI creador:
          </Typography>
          <Typography>
            {creador?.dni}
          </Typography>
        </Box>
      </Box>
      :
      null}

      {verificarPermiso(permisos, 'usuario_auditor') ?
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          Fecha creación:
        </Typography>
        <Typography>
          {obtenerFecha(fecha_creacion).fecha}
        </Typography>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
        Hora creación:
        </Typography>
        <Typography>
          {obtenerFecha(fecha_creacion).horario}
        </Typography>
      </Box>
    </Box>
    :
    null}

      {verificarPermiso(permisos, 'usuario_auditor') && eliminador ?
    <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          {eliminada ? "Eliminada por:" : "Ult. vez modificada por:"}
        </Typography>
        <Typography>
          {eliminador?.nombre}
        </Typography>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
          DNI:
        </Typography>
        <Typography>
          {eliminador?.dni}
        </Typography>
      </Box>
    </Box> 
    :
    null}
      

      {verificarPermiso(permisos, 'usuario_auditor') && eliminador ?
      <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"0.5em"}}>
      <Box sx={{display: "flex", flexDirection: "column"}}>
        <Typography mr={"1em"} fontWeight={"bold"}>
          {eliminada ? "Fecha eliminacion:" : "Fecha ult. modificación:"}
        </Typography>
        <Typography>
          {obtenerFecha(fecha_eliminacion).fecha}
        </Typography>
      </Box>
      <Box sx={{width: "47%", display: "flex", flexDirection: "column"}} >
        <Typography mr={"1em"} fontWeight={"bold"}>
        {eliminada ? "Hora eliminacion:" : "Hora ult. modificación:"}
        </Typography>
        <Typography>
          {obtenerFecha(fecha_eliminacion).horario}
        </Typography>
      </Box>
    </Box> 
    : null}      
      
     
        
       
        {verificarPermiso(permisos, 'usuario_administrador') && !eliminada ?
          <Typography
            sx={{
              maxWidth: "auto",
              display: "inline-block",
              mt: "1em",
              background: 'none',
              fontWeight: "bold",
              border: 'none',
              color: "primary.dark",
              "&:hover": {
                cursor: 'pointer',
              }
            }}
            onClick={general ? handleEditarGeneral : handleEditar}
          >
            Modificar datos*
        </Typography>
        :
          null}
        

      {/* Item 3 */}
      <Grid item xs={10} sm={10} md={8} lg={12}>
        {
          verificarPermiso(permisos, 'usuario_administrador') && !eliminada ?
            <Button 
              variant="contained"
              sx={{
                mb: 2,
                marginTop: "1.5em",
                fontSize: ".8em",
                fontWeight: "bold",
                bgcolor: 'background.paper',
                '&:hover': {
                  bgcolor: 'secondary.light',
                  transform: 'scale(1.01)',
                }
              }}
              onClick={handleClick}
            >
              Eliminar
            </Button>
          :
            null
        }
        
        
      </Grid> 
    </Grid>
  );
};  

export default AcercaJustificacion;