import React from 'react';

// MUI Componentes
import { 
  Grid,
  Typography,
} from '@mui/material';

const EstadisticaItem = ({ estadistica, handleClick }) => {
  const asistencia = estadistica?.empleados_presentes > 0 || estadistica?.empleados_justificados > 0 || estadistica?.empleados_ausentes > 0
                       ? `${estadistica?.tasa_asistencia.toFixed(2)}%` : 'S/C';

  return (
    <Grid
    item
    container
    sx={{
      width: '33%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',   
      cursor: 'pointer',
      textAlign: 'center'
    }}
    onClick={() => handleClick(estadistica.id)}
    >
    <Typography variant="h3" fontSize={'1.3em'} mb={1}>{estadistica?.nombre_dependencia}</Typography>
    <Typography>Usuarios: {estadistica?.usuarios_vigentes}</Typography>
    <Typography>Asistencia: {asistencia}</Typography>
  </Grid>
  );
};

export default EstadisticaItem;